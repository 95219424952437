(function ($) {
  'use strict'

  $(document).ready(function () {
    window.ISSgeo = {
      Var: {
        userLocation: {},
        markerGroup: [/* entities to be added to group/layer */],
        markerGroupLayer: {/* group layer obj holding markerGroup */},
        entityToLayer: [/* [Backbone entity ID] =
         * marker (layer holding entity pin/point) */],
        sideBar: {},
      },
      Map: {
        /* map instance */
      },
      Main: {
        hideOverlay: function () {
          var overlay = $('#leaflet-map .map-info-overlay')[0]
          $(overlay).remove()
          var arrow = $('#leaflet-map .map-down-arrow')[0]
          $(arrow).remove()
        },
        addZoomControl: function () {
          new L.Control.Zoom({ position: 'topright' }).addTo(ISSgeo.Map)
        },
        init: function () {
          // Set default location if none - somewhere at Sydney
          if (typeof ISSgeo.Var.userLocation.latitude === 'undefined') {
            ISSgeo.Var.userLocation = ISS.Var.options.get('userLocation')
          }

          var boundaryBox = ISS.Var.options.get('boundaryBox')

          if (boundaryBox) {
            ISSgeo.Var.LeafletBoundaryBox = L.latLngBounds(
              L.latLng(boundaryBox.Northeast.lat, boundaryBox.Northeast.lon),
              L.latLng(boundaryBox.Southwest.lat, boundaryBox.Southwest.lon),
            )
          } else {
            throw new Error('boundaryBox should be defined')
            // ISSgeo.Var.LeafletBoundaryBox = null;
          }

          // create a map in the "map" div, set the view to a given place and zoom
          ISSgeo.Map = new L.Map('leaflet-map',
            {
              center: new L.LatLng(
                ISSgeo.Var.userLocation.latitude,
                ISSgeo.Var.userLocation.longitude),
              keyboard: true,
              zoom: 13,
              zoomControl: false,
            })

          // Not for mobile
          if (!ISS.Var.isMobile) {
            L.DomUtil.create('div', 'map-top-shadow', $('#leaflet-map')[0])
            L.DomUtil.create('div', 'map-down-arrow', $('#leaflet-map')[0])

            var overlay = L.DomUtil.create('div', 'map-info-overlay col-md-5', $('#leaflet-map')[0])
            overlay.innerHTML = JST[ISS.Var.templatePath + 'map_info_overlay.hbs']({
              directoryName: ISS.Var.options.get('overlayDirectoryName'),
              directoryRegion: ISS.Var.options.get('directoryRegion'),
            })

            ISSgeo.Var.sideBar = L.control.sidebar('search-sidebar', {
              position: 'left',
              closeButton: false,
              autoPan: true,
            })

            ISSgeo.Map.addControl(ISSgeo.Var.sideBar)
          }

          // set default path to avoid errors
          L.Icon.Default.imagePath = '/'

          L.Icon.Default.iconRetinaUrl = require('leaflet/dist/images/marker-icon-2x.png')
          L.Icon.Default.iconUrl = require('leaflet/dist/images/marker-icon.png')
          L.Icon.Default.shadowUrl = require('leaflet/dist/images/marker-shadow.png')

          // listen to popup open event
          ISSgeo.Map.on('popupopen', function (evt) {
            ISSgeo.Var.currentPopup = evt.popup
          })

          // add google layer
          var gmapLayer = new L.Google('ROADMAP')
          ISSgeo.Map.addLayer(gmapLayer)

          // Set size according to screen
          $(window).on('resize', function () {
            ISSgeo.Main.resizeMap()
          }).trigger('resize')

          // Locate me control - Only for mobile
          if (ISS.Var.isMobile) {
            var LocateMeControl = L.Control.extend({
              options: {
                position: 'bottomleft',
              },

              onAdd: function (map) {
                // create the control container with a particular class name
                var container = L.DomUtil.create('div', 'map-locate-me')

                // ... initialize other DOM elements, add listeners, etc.
                $(container).append('<i class="fa fa-crosshairs"></i>')
                $(container).on('click', function () {
                  if (ISSgeo.Main.getUserCurrentLocation() === true) {
                    ISSgeo.Main.updateMyLocation()
                  }
                })
                return container
              },
            })

            ISSgeo.Map.addControl(new LocateMeControl())
          } else {
            ISSgeo.Main.addZoomControl()
          }

          if (ISSgeo.Main.getUserCurrentLocation() === true) {
            ISSgeo.Main.updateMyLocation()
          }

          // Using an interval for this because, after extensive searching,
          // I could not find an appropriate callback
          ISSgeo.Var.googleCleanUpInterval =
            window.setInterval(function () {
              if ($('a[href*="google.com"]').length === 3) {
                $('a[href*="google.com"]').each(function () {
                  $(this).attr('tabindex', '-1')
                })
                window.clearInterval(ISSgeo.Var.googleCleanUpInterval)
              }
            }, 300)
        },
        resizeMap: function () {
          var screenHeight = $('body').height()
          var heightFromTop = $('#leaflet-map').offset().top
          var $footer = $('#footer')
          var footerHeight = 0
          if ($footer.is(':visible')) {
            footerHeight = $footer.height()
          }
          var mapContainerSize = screenHeight - footerHeight - heightFromTop
          if (mapContainerSize < 30) {
            mapContainerSize = 30
          }
          $('#leaflet-map').height(mapContainerSize)
          $('#search-sidebar').height(mapContainerSize)
          ISSgeo.Map.invalidateSize()
        },
        updateMyLocation: function () {
          var position = ISSgeo.Var.userLocation
          ISSgeo.Map.panTo([position.latitude,
            position.longitude], {
            animate: false,
          })
          // Due to an issue with markers moving to wrong location for mobiles on TEST,
          // do not display markers to mobiles viewing the index home page
          if (!(ISS.Var.isMobile && $('#search-results').length === 0)) {
            ISSgeo.Main.setUserLocationMarker(position, 'default')
          }
          ISSgeo.Main.offsetMapBySideBar()
        },
        // Get User location from browser if available
        getUserCurrentLocation: function () {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              ISSgeo.Main._setUserLocation,
            )
            return true
          } else {
            return false
          }
        },
        _setUserLocation: function (position) {
          ISSgeo.Var.userLocation = position.coords
        },
        setUserLocationMarker: function (position, messageType) {
          // User pin icon
          var userIcon = L.icon({
            iconUrl: require('../../assets/img/marker-icon-red.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          })

          if (typeof ISSgeo.Var.userLocationMarker !== 'undefined') {
            ISSgeo.Map.removeLayer(ISSgeo.Var.userLocationMarker)
          }

          var message = '<i class="fa fa-info-circle"></i> '

          if (messageType === 'default') {
            message += 'This is your current location. ' +
              'All searches will be based on this location unless you manually enter a ' +
              'suburb or street address in the search box.'
          } else if (messageType === 'search-term') {
            message += 'This is your location based on your search criteria.'
          }

          ISSgeo.Var.userLocationMarker =
            L.marker([position.latitude, position.longitude],
              {
                icon: userIcon,
                keyboard: false,
              })
              .addTo(ISSgeo.Map)
              .bindPopup(message)
        },
        addEntityMarker: function (entity) {
          var data = entity.attributes
          if (!data.location || !data.location.point ||
            !data.location.point.lat || !data.location.point.lon) {
            return
          }

          var userIcon = L.icon({
            iconUrl: require('leaflet/dist/images/marker-icon.png'),
            shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41],
          })

          var marker = L.marker(
            [
              data.location.point.lat,
              data.location.point.lon,
            ], {
              keyboard: false,
              icon: userIcon,
            },
          )

          if (!ISS.Var.isMobile) {
            marker.bindPopup('<div id="mapEntityDetails_' + data.id + '" ></div>',
              {
                maxWidth: 300,
                minWidth: 300,
                maxHeight: 200,
                autoPan: true,
              },
            )
          }

          // open backbone entity detail view
          marker.on('click', function (e) {
            ISS.Var.detailsRequestor = 'map'
            ISS.Main.displayEntity(entity)
          })

          ISSgeo.Var.markerGroup.push(marker)
          ISSgeo.Var.entityToLayer[entity.id] = marker
          return marker
        },
        pinEntities: function () {
          if (ISSgeo.Var.markerGroup) {
            ISSgeo.Var.markerGroupLayer = L
              .featureGroup(ISSgeo.Var.markerGroup)
              .addTo(ISSgeo.Map)

            this.zoomToResults()
          }
        },
        unPinEntities: function () {
          if (!jQuery.isEmptyObject(ISSgeo.Var.markerGroupLayer)) {
            ISSgeo.Map.removeLayer(ISSgeo.Var.markerGroupLayer)
            ISSgeo.Var.markerGroupLayer = {}
            ISSgeo.Var.markerGroup = []
          }
        },
        zoomToResults: function () {
          if (ISSgeo.Var.currentPopup) {
            ISSgeo.Var.currentPopup._source.closePopup()
          }
          if (
            typeof ISSgeo.Var.markerGroupLayer.getBounds === 'function' &&
            ISSgeo.Var.markerGroupLayer.getBounds().isValid()
          ) {
            var newBounds = ISSgeo.Var.markerGroupLayer.getBounds()

            if (!ISSgeo.Var.LeafletBoundaryBox.contains(newBounds)) {
              ISSgeo.Map.fitBounds(ISSgeo.Var.LeafletBoundaryBox, {
                animate: false,
                maxZoom: 10,
              })
            } else {
              ISSgeo.Map.fitBounds(newBounds.pad(0.5), {
                animate: false,
              })
            }
            this.offsetMapBySideBar()
          }
        },
        openEntityPopup: function (entity) {
          var layer = ISSgeo.Var.entityToLayer[entity.id]
          if (!layer) {
            return
          }

          var latLng = layer.getLatLng()
          if (latLng) {
            layer.openPopup()

            ISSgeo.Map.setView(latLng, 16, {
              animate: false,
            })
            this.offsetMapBySideBar()

            if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.EntityDetailsMapView)) {
              ISS.ViewInstances.EntityDetailsMapView.close()
            }
            ISS.ViewInstances.EntityDetailsMapView =
              new ISS.Views.EntityDetailsMapView({ entity: entity })
          }
        },
        offsetMapBySideBar: function () {
          var sidebarWidth = $('#search-sidebar').outerWidth()
          ISSgeo.Map.panBy([-sidebarWidth / 2, 0], {
            animate: false,
          })
        },
      },
    }
  })
})(jQuery)
