(function ($) {
  'use strict'

  Handlebars.registerHelper({
    // Create a custom function helper to replace given string
    replaceStr: function (str, search, replace) {
      return str.replace(new RegExp(search, 'gim'), replace)
    },

    // Allow calling any template as a partial eg {{partial "templateName"}}
    partial: function (templateName) {
      var partialTemplatePath = ISS.Var.templatePath + 'partials/'
      return new Handlebars.SafeString(
        JST[partialTemplatePath + templateName](this).replace(/^\s+|\s+$/g, ''),
      )
    },

    // Lower case first then capitalize string
    toCapital: function (str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },

    subString: function (str, length) {
      if (str.length > length) {
        return str.substring(0, length - 4) + ' ...'
      } else {
        return str
      }
    },

    // Parse a string with line break characters into paragraphs.
    formatLineBreaks: function (str) {
      return str.split('\n').map(function (line) { return '<p>' + line + '</p>' }).join('')
    },

    // Find any URLs and make into links
    linkUrls: function (str) {
      // We match any url that starts with "http(s)://" or "www." and we ignore any full stops at the end
      // eslint-disable-next-line  max-len
      const urlRegEx = /(?:(https?:\/\/)|www\.)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*[-a-zA-Z0-9()@:%_+~#?&//=])?/gi
      return new Handlebars.SafeString(
        str.replace(
          urlRegEx,
          (url, protocol) => `
            <a
              href="${protocol ? '' : 'http://'}${url}"
              title="Click to visit the website for this listing, opens in a new window"
              target="_blank"
            >
              ${url}
            </a>
          `,
        ),
      )
    },

    // removes all non numeric characters from string
    onlyNumbers: function (str) {
      return str.replace(/\D/g, '')
    },

    pluralize: function (number, singular, plural) {
      if (number === 1) {
        return singular
      } else {
        return (typeof plural === 'string' ? plural : singular + 's')
      }
    },

    date: function (str) {
      return moment(str, 'YYYY-MM-DD').format('Do MMM YYYY')
    },

    time: function (str) {
      return moment(str, 'hh:mm:ss').format('h:mma')
    },

    ifAny: function () {
      var argLength = arguments.length - 2
      var content = arguments[argLength + 1]
      var success = false
      var i = 0
      while (i < argLength) {
        if (arguments[i]) {
          success = true
          break
        }
        i += 1
      }
      if (success) {
        return content.fn(this)
      } else {
        return content.inverse(this)
      }
    },

    compare: function (lvalue, rvalue, options) {
      if (arguments.length < 3) {
        throw new Error("'compare' needs 2 parameters")
      }

      var operator = options.hash.operator || '=='

      var operators = {
        'case-insensitive non-match': function (l, r) {
          return l.toLowerCase() !== r.toLowerCase()
        },
        '==': function (l, r) {
          return l == r // eslint-disable-line eqeqeq
        },
        '===': function (l, r) {
          return l === r
        },
        '!=': function (l, r) {
          return l != r // eslint-disable-line eqeqeq
        },
        '<': function (l, r) {
          return l < r
        },
        '>': function (l, r) {
          return l > r
        },
        '<=': function (l, r) {
          return l <= r
        },
        '>=': function (l, r) {
          return l >= r
        },
        typeof: function (l, r) {
          // String is a valid argument type for `r` so we can disable this rule
          // for this line.
          // eslint-disable-next-line valid-typeof
          return typeof l === r
        },
      }

      if (!operators[operator]) {
        throw new Error('Wrong operator ' + operator)
      }

      var result = operators[operator](lvalue, rvalue)

      if (result) {
        return options.fn(this)
      } else {
        return options.inverse(this)
      }
    },

    log: function (context) {
      return console.log(context)
    },

    isArray: function (purportedArray, options) {
      if (Handlebars.Utils.isArray(purportedArray)) {
        return options.fn(this)
      } else {
        return options.inverse(this)
      }
    },

    key_value: function (obj, options) {
      var buffer = ''
      var key

      for (key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          buffer += options.fn({ key: key, value: obj[key] })
        }
      }

      return buffer
    },

    ifStringLengthExceeds: function (value, length, options) {
      if (arguments.length < 3) {
        throw new Error("'ifLonger' needs 2 parameters")
      }
      if (typeof value !== 'string') {
        throw new Error("'ifLonger' first parameter should be a string")
      }
      if (value.length > length) {
        return options.fn(this)
      } else {
        return options.inverse(this)
      }
    },

    form2jsLabel: function (value) {
      if (typeof value !== 'string') {
        throw new Error("'form2jsLabel' parameter should be a string")
      }
      var res = value.split('.')

      return Handlebars.helpers.toCapital(res[res.length - 1])
    },

    trimLeadingComma: function (options) {
      return options.fn(this).replace(/^\s*,\s*/, '')
    },

    contextualise: function (data, defaultContext) {
      if (!data) {
        return ''
      } else if (!data.match(/\s*?\D+/)) {
        return defaultContext + ' ' + data
      } else {
        return data
      }
    },
  })
})(jQuery)
