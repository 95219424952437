export function setupViewFactories ($) {
  ISS.Factories.ViewFactoryBase = function () {
  }

  // All the common views go in the base class
  ISS.Factories.ViewFactoryBase.prototype = {
    createOriginView: function (options) {
      return new ISS.Views.OriginView(options)
    },
  }

  /**
   * DesktopViewsFactory implements function that return Views designed for a desktop/tablet screen
   */
  ISS.Factories.DesktopViewsFactory = function () {
  }

  // Inherit DesktopViewsFactory from ViewFactoryBase
  ISS.Factories.DesktopViewsFactory.prototype = new ISS.Factories.ViewFactoryBase()

  $.extend(ISS.Factories.DesktopViewsFactory.prototype, {
    createSearchPanelView: function (options) {
      return new ISS.Views.SearchPanelView(options)
    },

    createEntityListView: function (options) {
      return new ISS.Views.EntityListView(options)
    },

    createEntityDetailsView: function (options) {
      return new ISS.Views.EntityDetailsView(options)
    },

    createAddServiceView: function (options) {
      return new ISS.Views.AddServiceView(options)
    },
  })

  /**
   * PhoneViewsFactory implements a function that returns Views designed for a phone screen
   */
  ISS.Factories.PhoneViewsFactory = function () {
  }
  // Inherit PhoneViewsFactory from ViewFactoryBase
  ISS.Factories.PhoneViewsFactory.prototype = new ISS.Factories.ViewFactoryBase()
  // override
  $.extend(ISS.Factories.PhoneViewsFactory.prototype, {
    createSearchPanelView: function (options) {
      return new ISS.Views.SearchPanelViewMobile(options)
    },

    createEntityListView: function (options) {
      return new ISS.Views.EntityListViewMobile(options)
    },

    createEntityDetailsView: function (options) {
      return new ISS.Views.EntityDetailsViewMobile(options)
    },
  })
}
