export function getAddServiceView ($) {
  return Backbone.View.extend({
    templates: {
      addService: 'add_service.hbs',
    },
    render: function () {
      var $form
      var url = ISS.Var.options.get('issUrl')
      var template = JST[ISS.Var.templatePath + this.templates.addService]

      // Get everything before `api/`
      url = url.substr(0, url.indexOf('api/'))

      $('#service-directory-container').append(template())
      $('#add-service-modal').modal('show')

      $form = $('#add-service-form')

      $.ajax({
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        url: url + 'add-service-form',
      })
        .then(function (html) {
          $form.html(html)
        })
        .fail(function (response, status, xhr) {
          if (status === 'error') {
            $form.text('Error ' + xhr.status + ' ' + xhr.statusText)
          }
        })

      $form.get(0).addEventListener('formSubmitted', function () {
        $form.remove()
        $('#add-service-done').removeClass('hide')
      })
    },
  })
}
