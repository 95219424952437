
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div id=\"advancedSearchMobileContainer\">\n\n  <div class=\"top-panel-mobile\">\n    "
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "mobile_logo_bar.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n    <a id=\"backFromAdvancedSearch\" class=\"back-menu-mobile\" href=\"/#services\" title=\"Back to map\">\n        <span class=\"fa fa-arrow-left\"></span> Back to menu\n    </a>\n  </div>\n\n  <div class=\"col-sm-3 advanced-search-title\">\n    Advanced Search\n  </div>\n\n  "
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "advanced_search_form.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/advanced_search_mobile.hbs'] = templateFunction;
              export default templateFunction;
              