
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "#"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.id : stack1), depth0));
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\"shareModal\" class=\"modal fade share-modal\">\r\n  <div class=\"modal-dialog\">\r\n    <div class=\"modal-content\">\r\n      <div class=\"modal-header\">\r\n        <div class=\"row\">\r\n          <div class=\"col-xs-10\">\r\n            <h1 class=\"modal-title\">Share "
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.entity : depth0)) != null ? stack1.id : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</h1>\r\n          </div>\r\n          <div class=\"col-xs-2 text-right\">\r\n            <i tabindex=\"0\" title=\"Close this dialog\" class=\"fa fa-times close-modal\" data-dismiss=\"modal\"></i>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"modal-body\">\r\n\r\n        <div class=\"row share-button-container\">\r\n          <div class=\"col-xs-12\">\r\n            <a class=\"share-button\" href=\"https://www.facebook.com/sharer/sharer.php?u="
    + escapeExpression(((helper = (helper = helpers.shareUrl || (depth0 != null ? depth0.shareUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"shareUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\r\n              <i class=\"fa fa-facebook\"></i>\r\n              <span class=\"label\">Facebook</span>\r\n            </a>\r\n            <a class=\"share-button\" href=\"https://twitter.com/intent/tweet?text="
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "&url="
    + escapeExpression(((helper = (helper = helpers.shareUrl || (depth0 != null ? depth0.shareUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"shareUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\">\r\n              <i class=\"fa fa-twitter\"></i>\r\n              <span class=\"label\">Twitter</span>\r\n            </a>\r\n            <a class=\"share-button\" href=\"mailto:?subject="
    + escapeExpression(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper)))
    + "&body="
    + escapeExpression(((helper = (helper = helpers.emailUrl || (depth0 != null ? depth0.emailUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"emailUrl","hash":{},"data":data}) : helper)))
    + "\">\r\n              <i class=\"fa fa-envelope\"></i>\r\n              <span class=\"label\">Email</span>\r\n            </a>\r\n          </div>\r\n        </div>\r\n        <div class=\"row\">\r\n          <div class=\"col-xs-12\">\r\n            Link: \r\n            <div class=\"input-group\">\r\n              <input type=\"text\" class=\"form-control entity-url\" value=\""
    + escapeExpression(((helper = (helper = helpers.copyUrl || (depth0 != null ? depth0.copyUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"copyUrl","hash":{},"data":data}) : helper)))
    + "\" aria-label=\"Link to page\" readonly=\"readonly\">\r\n              <div class=\"input-group-btn\">\r\n                <button class=\"btn btn-primary share-copy-link\" type=\"button\"><i class=\"fa fa-copy\"></i></button>\r\n              </div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </div><!-- /.modal-content -->\r\n  </div><!-- /.modal-dialog -->\r\n</div><!-- /.modal -->\r\n";
},"useData":true});
              Handlebars.templates['src/hbs/share_modal.hbs'] = templateFunction;
              export default templateFunction;
              