export function getServiceTypesItem ($) {
  return Backbone.View.extend({
    events: {
      'click .list-group-item .badge': 'service_count_click',
      'click .list-group-item': 'service_type_click',
    },

    // Onclick of a service type we display the immediate children so we need
    // to also load the service counts of these children
    service_type_click: function (e) {
      var items = $(e.currentTarget).siblings('.list-group').children('div').children('.list-group-item:visible')
      ISS.ServiceCountHelper.load_children_st_counts(
        items,
      )
    },

    // On service count click, perform a search for services with
    // this service type in the searched for location
    service_count_click: function (e) {
      $('.last-clicked').removeClass('last-clicked')
      $(e.target).closest('.list-group-item').addClass('last-clicked')

      // Remember scroll position
      ISS.ViewInstances.ServiceTypesListView.saveScrollPosition()

      ISS.Var.simpleSearch = false
      ISS.Main.doServiceTypeSearch({
        area: $(e.currentTarget).data('st-area'),
        service_type_value: $(e.currentTarget).data('st-value'),
      })

      e.stopPropagation()
      return false
    },

    initialize: function (params) {
      this.render(params)
    },

    max_indent: 6,
    indent_px: 15,

    render: function (params) {
      var serviceType = params.service_type
      var indentLevel = params.indent_level
      var template = JST[ISS.Var.templatePath + 'service_type_item.hbs']
      // Only indent a maximum number of times to prevent ui from becoming unusable
      if (indentLevel > this.max_indent) {
        indentLevel = this.max_indent
      }

      // Add area for the template
      serviceType.area = params.area
      var renderedTemplate = template(serviceType)

      this.$el.append($(renderedTemplate))
      $(params.el_parent).append(this.$el)

      // If this service type has children, load the immediate children
      if (serviceType.children && serviceType.children.length > 0) {
        serviceType.children.forEach(function (childServiceType) {
          var childViewParams = {
            el_parent: '.service-type-' + serviceType.id,
            service_types_all: params.service_types_all,
            service_type: childServiceType,
            area: params.area,
            indent_level: indentLevel + 1,
          }
          ISS.ViewInstances.ServiceTypesItem = new ISS.Views.ServiceTypesItem(childViewParams)
        })
        this.$el.children('.list-group-item').removeClass('no-children')
      } else {
        // No children, hide the chevron on this item.
        this.$el.children('.list-group-item').addClass('no-children')
      }
      // set dynamic indent level
      this.$el.children('.list-group-item')
        .css('padding-left', (this.indent_px * indentLevel).toString() + 'px')
    },
  })
}

export function getServiceTypesListView ($) {
  return Backbone.View.extend({

    el: '<div id=serviceTypesList_container></div>',

    scroll_position: 0,
    location_coords: null,

    saveScrollPosition: function () {
      // The service types list view exists in the sidebar in desktop mode
      var searchSidebar = document.querySelector('#search-sidebar')
      this.scroll_position =
        searchSidebar !== null ? searchSidebar.scrollTop : window.pageYOffset
    },

    gotoScrollPosition: function () {
      var searchSidebar = document.querySelector('#search-sidebar')
      if (searchSidebar !== null) {
        document.querySelector('#search-sidebar').scrollTop =
          this.scroll_position
      } else {
        window.scrollTo(0, this.scroll_position)
      }
    },

    updateMapMarker: function (lat, lon) {
      // Update the marker to the location of the
      // service type search. This only needs ot occur once.
      if (this.location_coords === null) {
        this.location_coords = {}
        this.location_coords.latitude = lat
        this.location_coords.longitude = lon

        ISSgeo.Map.panTo([lat, lon], { animate: false })
        ISSgeo.Main.offsetMapBySideBar()
        ISSgeo.Main.setUserLocationMarker(this.location_coords, null)
      }
    },

    init_onscroll_events: function () {
      // create a single function reference to check and
      // prevent attaching handler more than once
      if (typeof ISS.ServiceCountHelper.onscroll_function !== 'function') {
        ISS.ServiceCountHelper.onscroll_function = function () {
          // Bundle scroll events, so only one is triggered per .25 seconds
          if (!ISS.ServiceCountHelper.scroll_load_pending) {
            ISS.ServiceCountHelper.scroll_load_pending = true
            setTimeout(function () {
              // Load service counts for the service types which are visible
              // ie. root service types and items which are visible
              ISS.ServiceCountHelper.load_children_st_counts(
                $('#service_types_container').find('.list-group-item:visible'),
              )
              ISS.ServiceCountHelper.scroll_load_pending = false
            }, 250)
          }

          // Remove pending pending api requests immediately for items where
          // we have moved outside of the viewport. Do this so there isnt a big queue
          // of pending ajax calls to complete before a real post i.e. new search submit
          for (var key in ISS.ServiceCountHelper._service_count_pending_queue) {
            if (!HSNetUtils.isInViewport(key)) {
              ISS.ServiceCountHelper._service_count_pending_queue[key].abort()
              delete ISS.ServiceCountHelper._service_count_pending_queue[key]
            }
          }
        }

        // For performance, load service counts on demand
        var scrollElement = $('#search-sidebar')
        if (scrollElement.length === 0) {
          scrollElement = $(window)
        } else {
          scrollElement = $('#search-sidebar')
        }
        scrollElement.on('scroll', ISS.ServiceCountHelper.onscroll_function)
      }
    },

    initialize: function (params) {
      var view = this

      view.search_location = params.search_location

      var serviceTypesCollection = new ISS.Models.ServiceTypeCollection()

      // Fetch the initial list of service types
      serviceTypesCollection.fetch({
        data: view.search_location,
        success: function (collection, response) {
          collection.models = collection.sortBy('value')

          var template = JST[ISS.Var.templatePath + 'service_types_container.hbs']
          view.$el.append(
            $(template()),
          )
          $('#serviceTypesList_container').append(view.el)

          var rootServiceTypeEl = '.service-type-root'
          // Render the root service types.
          var viewParams = {
            el_parent: rootServiceTypeEl,
            service_types_all: collection.models,
            parent_container_selector: '.service-type-root',
          }

          collection.each(function (serviceType) {
            // Add the service type to render
            viewParams.service_type = serviceType.attributes
            viewParams.area = view.search_location
            viewParams.indent_level = 1

            ISS.ViewInstances.ServiceTypesItem = new ISS.Views.ServiceTypesItem(viewParams)
          })
          // load the service counts for the first root service types
          ISS.ServiceCountHelper.load_children_st_counts(
            $(rootServiceTypeEl + ' > div').children('.list-group-item:visible'),
          )

          // Set list group javascript
          $('.list-group-item').on('click', function (e) {
            // This event could fire if the badge is clicked.
            // If the badge is clicked we don't want to toggle the list item
            if ($(e.target).closest('.badge').length > 0) { return }

            $('.glyphicon', this)
              .toggleClass('glyphicon-chevron-right')
              .toggleClass('glyphicon-chevron-down')

            // Bootstrap should do this automatically, however its not for some reason
            $($(this).data('target')).toggle()
          })
          var message = 'You have searched for location: ' + view.search_location
          $('#totalRecords_container').html(message)

          // initialise onscroll events
          view.init_onscroll_events()
        },
      })
    },
  })
}
