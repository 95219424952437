export function getServiceEntity ($) {
  return ISS.Models.EntityModel.extend({
    buildName: function (fieldName) {
      // NB: If, in the future, we want to edit multiple services
      // simultaneously, the fieldname must reflect this by inserting an array
      // index for each service being edited.
      // E.g. 'service_fieldset[0]__fieldName' for the first service.
      return 'service_fieldset__' + fieldName
    },

    /**
     * Get the fields for contact information for this site.
     *
     *  Note that these fields need to appear in a specific order which differs
     *  from the order they should appear in for other entities. They also have
     *  different field names.
     */
    getContactFields: function () {
      var fields = []
      var phones = this.attributes.phones

      // ISS Update can only handle the following phone numbers. If there are
      // more, we'll just ignore them.
      var phone1 = {
        value: '',
        name: 'service_phone1',
        label: 'Phone 1',
        input: true,
        type: 'tel',
      }
      var phone1Contact = {
        value: '',
        name: 'contact1',
        label: 'Contact 1',
        input: true,
      }
      var phone2 = {
        value: '',
        name: 'service_phone2',
        label: 'Phone 2',
        input: true,
        type: 'tel',
      }
      var phone2Contact = {
        value: '',
        name: 'contact2',
        label: 'Contact 2',
        input: true,
      }
      var fax = {
        value: '',
        name: 'service_fax',
        label: 'Fax',
        input: true,
        type: 'tel',
      }
      var mobile = {
        value: '',
        name: 'service_mobile_phone',
        label: 'Mobile',
        input: true,
        type: 'tel',
      }
      var tty = {
        value: '',
        name: 'service_tty',
        label: 'TTY',
        input: true,
        type: 'tel',
      }
      var freecall = {
        value: '',
        name: 'service_freecall',
        label: 'Free Call',
        input: true,
        type: 'tel',
      }

      for (var i = 0; i < phones.length; i++) {
        var phone = phones[i]
        switch (phone.kind) {
          case 'phone':
            if (!phone1.value) {
              phone1Contact.value = phone.comment
              phone1.value = phone.number
            } else if (!phone2.value) {
              phone2Contact.value = phone.comment
              phone2.value = phone.number
            }
            break
          case 'fax':
            fax.value = phone.number
            break
          case 'mobile':
            mobile.value = phone.number
            break
          case 'tty':
            tty.value = phone.number
            break
          case 'freecall':
            freecall.value = phone.number
            break
          default:
            break
        }
      }

      var email = {
        value: '',
        name: 'service_email',
        label: 'Email',
        input: true,
        type: 'email',
      }
      var emails = this.attributes.emails
      if (emails.length > 0) {
        email.value = emails[0].email
      }

      fields.push(phone1Contact)
      fields.push(phone1)
      fields.push(phone2Contact)
      fields.push(phone2)
      fields.push(fax)
      fields.push(mobile)
      fields.push(tty)
      fields.push(freecall)
      fields.push(email)

      return fields
    },

    getFields: function () {
      // Get the fields for this service's site, which need to be
      // submitted along with the service's fields.
      var site = new ISS.Models.SiteEntity({
        type: 'site',
        id: this.attributes.site.id,
      })

      site.fetch({ async: false })

      // Don't display the site fields, except user contact details
      var siteFields = site.getFields()
      _.each(siteFields, function (field, index, fields) {
        if (!field.user_contact) {
          field.hidden = true
        }
      }, this)

      // Remove the (hidden) accessibility and parking fields for the
      // site. We'll redefine them below in the correct place in the form.
      siteFields = _.filter(siteFields, function (field) {
        if (
          field.name === 'accessibility' ||
          field.name === 'parking_info'
        ) {
          return false
        }
        return true
      })

      var fields = [
        {
          name: 'service_docid',
          value: this.attributes.id,
          hidden: true,
        },
        {
          name: 'site_docid',
          value: site.attributes.id,
          hidden: true,
        },
        // Include the original name of the service.
        {
          value: this.prepareField(this.attributes.name),
          name: 'email_service_name',
          hidden: true,
        },
        // TODO: include Site Name.
        {
          value: this.prepareField(this.attributes.name),
          name: 'service_name',
          label: 'Service Name',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.description),
          name: 'service_description',
          label: 'Service Description',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.eligibility_info),
          name: 'eligibility_info',
          label: 'Eligibility',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.ineligibility_info),
          name: 'ineligibility_info',
          label: 'Ineligibility',
          textarea: true,
        },

        // TODO: languages

        {
          value: this.prepareField(this.attributes.funding_body),
          name: 'funding_body',
          label: 'Funding body',
          input: true,
        },
      ]

      fields = siteFields.concat(fields)
      fields = fields.concat(this.getContactFields())

      var remainingFields = [
        {
          value: this.prepareField(this.attributes.web),
          name: 'service_website',
          label: 'Web',
          input: true,
          type: 'url',
        },

        // TODO: office hours
        {
          value: this.prepareField(this.attributes.catchment),
          name: 'catchment',
          label: 'Catchment',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.cost),
          name: 'cost',
          label: 'Cost to clients',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.billing_method),
          name: 'billing_method',
          label: 'Billing Method',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.availability),
          name: 'availability',
          label: 'Availability',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.referral_info),
          name: 'referral_info',
          label: 'Referral',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.special_requirements),
          name: 'special_requirements',
          label: 'Special Requirements',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.assessment_criteria),
          name: 'assessment_criteria',
          label: 'Assessment',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.intake_info),
          name: 'intake_info',
          label: 'Intake Information',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.intake_point),
          name: 'intake_point',
          label: 'Intake Point',
          input: true,
        },
        {
          value: this.attributes.ndis_approved,
          name: 'ndis_approved',
          label: 'NDIS Approved',
          checkbox: true,
        },
        {
          value: this.attributes.adhc_eligible,
          name: 'adhc_eligible',
          label: 'ADHC Eligible Provider',
          checkbox: true,
        },
        {
          value: this.attributes.healthcare_card_holders,
          name: 'healthcare_card_holders',
          label: 'Accepts Health Care Cards',
          checkbox: true,
        },
        // TODO: address fields (from site)
        // TODO: HSNA.Access.transport Note this field is not presently in the
        // ISS3 data.
        {
          value: this.prepareField(this.attributes.parking_info),
          name: 'parking_info',
          label: 'Parking',
          textarea: true,
        },
      ]
      return fields.concat(remainingFields)
    },
  })
}
