// ISS service types collection.
export function getServiceTypeCollection ($) {
  return Backbone.Collection.extend({
    url: function () {
      return ISS.Main.makeIssUrl('servicetypes/', {}, '')
    },
    parse: function (response, options) {
      if (!response) {
        return false
      }
      return response
    },
  })
}
