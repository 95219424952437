
/**
 * Main view to attach all events related to the search panel
 **/
export function getHomeViewBase ($) {
  return Backbone.View.extend({
    events: {
      'submit #iss-search_form': 'search',
      'click #iss-search_submit': 'search', // <button> does not fire submit
      'click button.advanced_search': 'openAdvancedSearch',
    },
    openAdvancedSearch: function () {
      ISS.Var.routerMain.services()
      ISS.Var.routerMain.navigate('services/advanced-search', { trigger: true })
      return false
    },
    search: function () {
      var searchTerm = $('#iss-keyword-search_input').val()
      // Fixes an issues where hitting the back button from
      // search results back to the homepage results in the search
      // not being able to fire.
      if (
        HSNetUtils.history.currentState() === '/'
      ) {
        ISS.Var.routerMain.services()
      } else {
        ISS.Var.routerMain.servicesOnce()
      }

      ISS.Var.simpleSearch = true

      $('#iss-keyword-search_input').val(searchTerm)
      ISS.Main.submitSearch()
      return false
    },

    append_el: function (params) {
      params.container.append(this.$el)
      return this
    },
  })
}

export function getOriginView ($) {
  return ISS.Views.HomeViewBase.extend({
    el: '#service-directory-container',
    _element_id: function () {
      return (this.el instanceof String)
        ? this.el : this.$el.attr('id')
    },
    reattach: function () {
      this.$el.empty().append(this._detached_html)
      return this
    },
    remove: function () {
      var elementId = this._element_id()
      var parent = this.$el.parent()

      this._detached_html = this.$el.detach().html()
      this.el = document.createElement('DIV')
      this.el.id = elementId
      this.$el = $(this.el)
      $(parent).append(this.el)

      return this
    },
  })
}
