/**
 * EntityCollection ... backbone collection.

  * We have a meta field, which will contain the 'meta' field on the json
  * returned by ISS3.

  *
**/
export function getEntityCollection ($) {
  return Backbone.Collection.extend({
    model: function (attributes, options) {
      switch (attributes.type) {
        case 'service':
          return new ISS.Models.ServiceEntity(attributes, options)
        case 'site':
          return new ISS.Models.SiteEntity(attributes, options)
        default:
          return new ISS.Models.EntityModel(attributes, options)
      }
    },

    url: function () {
      return ISS.Main.makeIssUrl('search/', {})
    },

    parse: function (response, options) {
      if (!response) {
        return options.error()
      }

      // If we get an error in our response - something has gone wrong
      // so we assume no results.
      if (response.error) {
        if (response.data && response.data.http_code === 402) {
          // Return a response of no objects, we dont return options.error
          // since we want to pass the iss3 error message back to the view.
          return []
        }
      }

      this.meta = jQuery.extend({}, response.meta)

      // let's handle crisises! (page 1 only)
      if (!options.data.offset) {
        var legitCrisis = false
        _.each(response.objects, function (entity, index, list) {
          if ((index === 0) && (entity.crisis === true)) {
            legitCrisis = true
            if (entity.location && entity.location.point) {
              // If this is a crisis service we will remove the location.point key so that
              // navigating to it doesn't move us away from the results in the map
              // We set a new key "crisis_point" to later compute if this service is confidential or not.
              list[index].location.crisis_point = list[index].location.point
              list[index].location.point = null
            }
          } else if ((legitCrisis === true) && (entity.crisis === true)) {
            if (entity.location && entity.location.point) {
              list[index].location.crisis_point = list[index].location.point
              list[index].location.point = null
            }
          } else if ((legitCrisis === true) && (entity.crisis !== true)) {
            legitCrisis = false
          } else if ((legitCrisis === false) && (entity.crisis === true)) {
            list[index].crisis = false
          }
        })
      }

      return response.objects
    },
    meta: {},
  })
}
