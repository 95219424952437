export function getEntityDetailsMapView ($) {
  return Backbone.View.extend({
    template: function () {
      return JST[ISS.Var.templatePath + 'map_entity_details.hbs']
    },
    initialize: function (params) {
      var myEl = $('#mapEntityDetails_' + params.entity.id)

      myEl.html(
        ISS.Main.highlightSearchTerms(
          this.template()(params.entity.attributes),
        ),
      )

      return this
    },
  })
}
