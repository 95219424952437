
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "		<a class=\"backToResultsListLink\" href=\"#services/results\" title=\"Back to results list\"><span class=\"fa fa-arrow-left\"></span>Back to results list</a>\n";
  },"3":function(depth0,helpers,partials,data) {
  return "			<div class=\"flag-container\">\n				<span class=\"atsi-flag\">\n					<img src=\"/8a8f8764c29333c0c85c82de0b341095.svg\">\n				</span>\n				<span class=\"atsi-flag\">\n					<img src=\"/ded685c7d025d574ce1fe46c7ce5d324.svg\">\n				</span>\n				<div class=\"sr-only\">\n					Services for Aboriginal &amp; Torres Strait Islander people\n				</div>\n			</div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "<div class=\"row directory-details-controls\">\n	<a class=\"col-xs-4 directory-details-controls-print\" title=\"Print\" href=\"#\" onclick=\"window.print(); return false\"><span class=\"fa fa-print\"></span> Print\n	</a>\n	<a class=\"col-xs-4 directory-details-controls-share openShareModal\" href=\"#\"><span class=\"fa fa-share\"></span> Share\n	</a>\n	<a id=\"openPUR\" class=\"col-xs-4 directory-details-controls-report\" type=\"button\" title=\"Report an Error\" href=\"javascript:;\"><span class=\"fa fa-exclamation-circle\"></span> Report an Error\n	</a>\n</div>\n<div class=\"directory-details-top\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.requestor : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "	<p class=\"directory-details-last-updated pull-right clearfix\">Last updated: "
    + escapeExpression(((helpers.date || (depth0 && depth0.date) || helperMissing).call(depth0, (depth0 != null ? depth0.last_updated : depth0), {"name":"date","hash":{},"data":data})))
    + "</p>\n</div>\n<div class=\"row\">\n	<h2 class=\"col-xs-9 directory-details-header\">"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</h2>\n	<div class=\"col-xs-3 directory-details-label\">\n		<span class=\"label label-success label-round\">"
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "</span>\n	</div>\n</div>\n<div class=\"row\">\n	<div class=\"col-xs-12 header-flags\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showIndigenousFlags : depth0), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</div>\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/partials/entity_controls.hbs'] = templateFunction;
              export default templateFunction;
              