export function getOptions ($) {
  return function (options) {
    this.get = function (property) {
      if (_.isFunction(options[property])) {
        return options[property]()
      } else {
        return options[property]
      }
    }
  }
}
