export function getMainRouter ($) {
  return Backbone.Router.extend({
    _viewFactory: null,
    _$container: null,

    initialize: function (options) {
      this._viewFactory = options.viewFactory
      this._$container = $('#service-directory-container')
    },

    // Define routes
    routes: {
      'services/servicetypes/:params': 'serviceTypesList',
      'add-service': 'add-service',
      'search/:params': 'search',
      'services(/)': 'services',
      'services/advanced-search(/)': 'advanced-search',
      'services/results(/)': 'results',
      'services/results/list(/)': 'resultslist',
      'services/results/map(/)': 'resultsMap',
      'services/results/:query/:location': 'results',
      'services/results/:query': 'results',
      'services/:type/:id(/)': 'details',
      'services/servicetypes(/)': 'serviceTypesList',
      'tests(/)': 'tests',
      '': 'services',
    },

    search: function (params) {
      this.servicesOnce()

      if (params) {
        $('#iss-keyword-search_input').val(decodeURIComponent(params))
        // Calling submit will stop execution here and some css styles
        // don't completely get applied. User setTimeout to make it async
        // and not break current execution.
        setTimeout(function () {
          $('#iss-search_form').submit()
        }, 1)
      }
    },

    tests: function () {
      this.servicesOnce()
      window.jasmineInit()
    },

    'advanced-search': function () {
      HSNetUtils.history.add()
      if (HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.SearchPanelView)) {
        // We have hit this route before anything is initialised.
        // Send to Home.
        ISS.Var.routerMain.navigate('', { trigger: true })
      } else {
        ISS.ViewInstances.SearchPanelView.renderAdvancedSearch()
        return this
      }
    },

    // _hashReplaced is ONLY used by the serviceTypesList route to determine
    // if a hashchange has occurred and we are just replacing the base64 encoding
    // of the hash with the normal version. See further note in the route.
    _hashReplaced: false,

    // Show the service types list.
    // params: query params from the route. Currently just the location is passed.
    serviceTypesList: function (params) {
      // TODO: remove this when https://github.com/jashkenas/backbone/issues/4085
      // is merged into Backbone.
      // Due to the bug, we need to pass a base64 encoded query string into backbone's
      // navigate() in order to not trigger the double route trigger bug.
      if (ISS.Routers.Main.hashReplaced) {
        ISS.Routers.Main.hashReplaced = false
        return
      }
      // Decode if the params is base64 encoded
      // and its not a postcode (4 decimal digits are valid base64 encoding, so need to check).
      var postcodeRegex = new RegExp(HSNetUtils.postcode_regex)
      var base64Regex = new RegExp(HSNetUtils.base64_regex)
      if (params !== null &&
        params.match(postcodeRegex) === null &&
        params.match(base64Regex) !== null
      ) {
        var oldParam = params
        params = window.atob(params)
        // Additionally, we try to replace the base64 hash in the url with the decoded
        // location for usability.
        // Replacing the hash triggers Backbone's history onhashchange event which cause's
        // a route to trigger again. Solution is to set a variable (_hashReplaced)
        // so we know when to not run this route again from this particular event.
        if (history.replaceState) {
          ISS.Routers.Main.hashReplaced = true
          history.replaceState(null, null, window.location.hash.replace(oldParam, params))
        }
      }
      // End workaround

      var listViewAttributes = null
      // Display map view button.
      if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.SearchPanelView)) {
        ISS.ViewInstances.SearchPanelView.toggleViewMapButton(false)
      }
      ISS.Main.displayMobileMap(false)
      if (params) {
        var location = decodeURIComponent(params)
        listViewAttributes = {
          search_location: location,
        }
      } else {
        // Use a previous location if available.
        if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.ServiceTypesListView)) {
          listViewAttributes = {
            search_location: ISS.ViewInstances.ServiceTypesListView.search_location,
          }
        }
      }

      HSNetUtils.history.add()

      if (listViewAttributes) {
        // We have a location
        // Remove any entityList controls if we came from there.
        if (ISS.ViewInstances.entityListView) {
          ISS.ViewInstances.entityListView.undelegateEvents()
          ISS.ViewInstances.entityListView.remove()
        } else {
          // Initialise if we came to route directly via url prior to any services search.
          this.servicesOnce()
        }
        $('#iss-location-search_input').val(listViewAttributes.search_location)
        ISS.Main.showSideBar()
        // Check if ISS.ViewInstances.ServiceTypesListView already exists and if
        // the search params are the same. If they are, then we should reuse the view.
        // It allows us to also keep the drilled-down state of the service type tree.
        var viewInstance = ISS.ViewInstances.ServiceTypesListView
        if (!HSNetUtils.isFalsyOrEmpty(viewInstance) &&
          viewInstance.search_location === listViewAttributes.search_location) {
          // Reuse view instance
          $(viewInstance.el).show()
          ISS.Main.appendViewToContainer(viewInstance)
          viewInstance.gotoScrollPosition()
        } else {
          // Create a new service types list view with a new search.
          if (!HSNetUtils.isFalsyOrEmpty(viewInstance)) {
            // Clean up old view.
            viewInstance.remove()
            viewInstance.close()
          }
          ISS.ViewInstances.ServiceTypesListView =
            new ISS.Views.ServiceTypesListView(listViewAttributes)
          ISS.Main.appendViewToContainer(ISS.ViewInstances.ServiceTypesListView)
        }
      } else {
        // No area supplied. Navigate to search.
        ISS.Var.routerMain.navigate('services', { trigger: true })
      }
    },

    results: function (queryString, locationString) {
      var backToMapView = HSNetUtils.history.getPreviousState() === '/#services/results/map'
      HSNetUtils.history.add()

      if (ISS.Var.isMobile) {
        if (HSNetUtils.history.getPreviousState() === '/#services/advanced-search') {
          $('#advancedSearchMobileContainer').remove()
          $('#services-search').show()
          $('.search-result-container').show()
          return this
        } else if (HSNetUtils.history.getPreviousState() === '/#services/results/map') {
          ISS.Main.displayMobileMap(false)
        } else if (ISS.ViewInstances.SearchPanelView.el) {
          $('#entityDetailsMobileContainer').parent().remove()
          $('#wrap').append(ISS.ViewInstances.SearchPanelView.el)

          var $searchView = ISS.ViewInstances.SearchPanelView
          $searchView.delegateEvents()
          if (ISS.ViewInstances.entityListView) {
            $('.search-result-container')
              .show()
              .html(ISS.ViewInstances.entityListView.el)
          }

          if (backToMapView) {
            $('#issSearchResultsMobileLnk').click()
          }
        }

        if (queryString !== null) {
          this.servicesOnce()
          if (locationString) {
            $('#iss-location-search_input').val(decodeURIComponent(locationString))
          }
          $('#iss-keyword-search_input').val(decodeURIComponent(queryString))
          ISS.Var.simpleSearch = true
          ISS.Main.submitSearch()
        }

        return this
      }

      if (ISS.ViewInstances.entityListView) {
        if (HSNetUtils.history.getPreviousState() &&
            HSNetUtils.history.getPreviousState().indexOf('#') === -1) {
          // Coming from pages where services() hasn't been rendered eg homepage
          this.servicesOnce()
        }
        ISS.Main.appendViewToContainer(ISS.ViewInstances.entityListView)

        // relocate map to the search results
        ISSgeo.Main.zoomToResults()
        ISS.Main.showSideBar()

        var query
        let location
        if (ISS.ViewInstances.entityListView.params.simpleSearchString === '') {
          query = HSNetUtils.unescapeElasticOperators(
            ISS.ViewInstances.entityListView.params.queryObj.q,
          )
          location = HSNetUtils.unescapeElasticOperators(
            ISS.ViewInstances.entityListView.params.queryObj.area,
          )
        } else {
          // string coming from advanced search
          query = ISS.ViewInstances.entityListView.params.simpleSearchString
          location = ISS.ViewInstances.entityListView.params.simpleLocationString
        }

        // If the current query and search terms in url differ, submit that
        // as a new search.
        if (queryString !== null && (queryString !== query || locationString !== location)) {
          if (locationString) {
            ISS.ViewInstances.entityListView.params.queryObj.area = locationString
            ISS.ViewInstances.entityListView.params.simpleLocationString = locationString
            $('#iss-location-search_input').val(decodeURIComponent(locationString))
          }
          ISS.ViewInstances.entityListView.params.queryObj.q = queryString
          ISS.ViewInstances.entityListView.params.simpleSearchString = queryString
          ISS.Var.simpleSearch = true
          $('#iss-keyword-search_input').val(queryString)
          // Provide queryString as param
          var params = {}
          ISS.Main.submitSearch(params)
        } else {
          $('#iss-keyword-search_input').val(query)
          $('#iss-location-search_input').val(location)
        }

        $('#search-sidebar').scrollTop(ISS.listScrollPos)
      } else {
        this.servicesOnce()

        // If we don't currently have results shown, but the user has provided
        // search terms via a url, then submit it as a search.
        if (queryString !== null) {
          $('#iss-keyword-search_input').val(decodeURIComponent(queryString))
          if (locationString) {
            $('#iss-location-search_input').val(decodeURIComponent(locationString))
          }
          ISS.Var.EntityListRequestor = 'SearchPanelViewBase'
          ISS.Var.simpleSearch = true
          ISS.Main.submitSearch()
        }
      }
    },
    resultslist: function () {
      HSNetUtils.history.add()
      if (ISS.ViewInstances.entityListView) {
        $('#entityDetailsMobileContainer').parent().remove()
        $('#wrap').append(ISS.ViewInstances.SearchPanelView.el)

        var $searchView = ISS.ViewInstances.SearchPanelView
        $searchView.delegateEvents()
        $('.search-result-container')
          .show()
          .html(ISS.ViewInstances.entityListView.el)
      } else {
        return this.servicesOnce()
      }
    },

    // Show search results in map view
    // Mobile only
    resultsMap: function () {
      HSNetUtils.history.add()
      ISS.Main.displayMobileMap(true)
    },
    details: function (type, id) {
      HSNetUtils.history.add()
      if (ISS.ViewInstances.entityListView) {
        var entity = ISS.ViewInstances.entityListView.collection.get(id)
        if (entity) {
          ISSgeo.Main.addEntityMarker(entity)
          ISSgeo.Main.pinEntities()
          ISS.Main.displayEntity(entity)
          ISS.Main.showSideBar()
          return this
        }
      }

      this.servicesOnce()

      var model
      var args = { type: type, id: id }
      if (type === 'service') {
        model = new ISS.Models.ServiceEntity(args)
      } else if (type === 'site') {
        model = new ISS.Models.SiteEntity(args)
      } else {
        model = new ISS.Models.EntityModel(args)
      }

      model.fetch({
        data: {},
        success: function (entity, response, options) {
          window.setTimeout(function () {
            if (entity) {
              // Create map marker.
              var marker = ISSgeo.Main.addEntityMarker(entity)
              if (marker) {
                ISSgeo.Main.pinEntities()
              }

              ISS.Main.displayEntity(entity)
              ISS.Main.showSideBar()
            } else {
              return options.error()
            }
          }, 1000)
        },
        error: function (collection, response, options) {
          // TODO show some kind of record not found/unavailable modal.
          ISS.Var.routerMain.servicesOnce()
        },
      })
    },

    // Hacky wrap, ensures services() does not get called more than once
    // Routes shold not, as a rule, call other routes
    // But, we are currently doing this since apparently services() contains
    // some application initialisation code (such as loading the map)
    servicesOnce: function () {
      if (!ISS.Var.haveWeRunServicesOnce) {
        return this.services()
      }
    },
    // These callbacks get called when navigate is called with
    // trigger = true
    services: function () {
      ISS.Var.haveWeRunServicesOnce = true
      HSNetUtils.history.add()
      if (ISS.Var.isMobile) {
        $('#entityDetailsMobileContainer').parent().remove()

        var view = {}
        if (ISS.Var.detailsRequestor === 'results') {
          view = ISS.ViewInstances.entityListView.el
        } else {
          view = ISS.ViewInstances.SearchPanelView.el
        }
        if (typeof view !== 'undefined') {
          // Fix where going back to home from results causes
          // the search results never to appear again.
          if (ISS.Var.routerCurrentPage !== 'Home') {
            $('#wrap').append(view)
            return this
          }
        }
      }
      this.removeView()
      if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.SearchPanelView)) {
        ISS.ViewInstances.SearchPanelView.close()
      }
      ISS.ViewInstances.SearchPanelView = this._viewFactory.createSearchPanelView()

      ISS.Var.routerCurrentPage = 'services'
      ISS.Var.routerViews[ISS.Var.routerCurrentPage] = ISS.ViewInstances.SearchPanelView

      // TODO: This is temporary as removeView() should already have cleaned the view
      this._$container.empty()

      // handle navbar
      if (!ISS.Var.isMobile) {
        this._navControl._showNavActive()
      }

      ISS.ViewInstances.SearchPanelView.render()

      ISSgeo.Main.init()
    },

    removeView: function () {
      if (!ISS.Var.routerViews) {
        ISS.Var.routerViews = {}
        return
      }
      var view = ISS.Var.routerViews[ISS.Var.routerCurrentPage]
      if (typeof view !== 'undefined' && typeof view.remove === 'function') {
        view.remove()
      }
    },

    _navControl: {
      _inUse: null,
      $navbar: $(),
      $originalItem: $(),
      $originalAnchor: $(),
      $sdAnchor: $(),

      _showNavActive: function () {
        if (!this._inUse) {
          this._inUse = true
          this.$navbar = $('#wrap .navbar .nav').eq(0)
          this.$originalItem = this.$navbar.find('li.active')
          this.$originalAnchor = this.$originalItem.find('a')
          this.$sdAnchor =
            this.$navbar.find('a[href$="#services"]').eq(0)
        }
        this.$navbar.closest('div.navbar-primary').removeClass('hide')

        this.$originalItem.removeClass('active')

        $('body').addClass('is-service-directory')

        this.$sdAnchor.prepend('<i class="fa fa-caret-down"></i>')
          .parent('li').addClass('active')
      },

      _restoreNavOriginal: function () {
        if (!this._inUse) {
          return
        }

        this.$originalItem.addClass('active')
        this.$sdAnchor.text('Find services')
          .parent('li').removeClass('active')
        this.$navbar.closest('div.navbar-primary').addClass('hide')

        $('body').removeClass('is-service-directory')

        this._inUse = null
      },
    },
  })
}
