(function ($) {
  'use strict'

  window.ISS = window.ISS || {}

  const getStateRestriction = () => window.ISS.Var.options.get('restrictSuggestionsToState')

  window.ISS.Suggestions = {
    get searchBoxes () {
      return [
        {
          selector: '.search-services-box',
          options: {
            endpointPath: 'suggest/',
            params: {
              start: '%QUERY',
              type: ['organisation', 'service', 'practitioner', 'keyword', 'service_type', 'language'],
            },
          },
        },
        {
          selector: '.search-locations-box',
          options: {
            endpointPath: 'location/search/',
            params: {
              name: '%QUERY',
              kind: ['suburb', 'postcode'],
              ...(getStateRestriction() ? { state: getStateRestriction() } : {}),
            },
          },
        },
      ]
    },
    init: function () {
      $(document).ready(() => {
        for (const searchBox of this.searchBoxes) {
          this.setupSearchBox(searchBox)
        }
      })
    },
    setupSearchBox: function ({ selector: searchBoxSelector, options }) {
      if (!$(searchBoxSelector).hasClass('tt-query')) {
        var typeaheadEl = $(searchBoxSelector).typeahead({
          remote: {
            url: ISS.Main.makeIssUrl(
              options.endpointPath,
              options.params,
            ),
            filter: (result) => {
              return result.objects.map(object => {
                const includeState = object.kind !== 'postcode' && !getStateRestriction()
                return { value: object.value || (includeState ? `${object.name}, ${object.state}` : object.name) }
              })
            },
            rateLimitWait: 100,
          },
          limit: 10,
        })

        /**
         * --- Predefined suggestions:
         * Show dropwdown with keywords suggestions if they are specified in
         * ISS.Var.options.predefinedKeywordSuggestions
         *
         * This is a bit hacky approach and could use of refactoring
         * and/or Typeahead upgrade to version which supports this
         * out of the box (is Typeahead v0.11.0+ out?)
         *
        **/
        var theTTdropdownMenu = $('.tt-dropdown-menu')
        var theSuggestionLink = $('.show-suggestions-link')
        var predefinedSuggestions = ISS.Var.options.get('predefinedKeywordSuggestions')
        predefinedSuggestions = predefinedSuggestions !== undefined ? predefinedSuggestions.length > 0 : false

        if (theTTdropdownMenu && theSuggestionLink && predefinedSuggestions) {
          var data = { suggestions: ISS.Var.options.get('predefinedKeywordSuggestions') }
          var template = JST[ISS.Var.templatePath + 'predefined_keywords.hbs']
          var thePredefinedMenuSelector = '.tt-predefined'

          var initPredefinedSuggestions = function () {
            // first avoid duplicates
            $(thePredefinedMenuSelector).remove()

            $(theTTdropdownMenu).append(template(data))

            $(thePredefinedMenuSelector + ' .tt-suggestion').on('click', function (e) {
              $(searchBoxSelector).val($(this).text())
              $('#iss-search_form').submit()
            })
          }

          theSuggestionLink.show()
          initPredefinedSuggestions()

          // Hide the predefeined suggestions when user starts typing
          // Show when the input box is empty or user clicks the show link
          $(searchBoxSelector).on('input', function (e) {
            if ($(this).val()) {
              $(thePredefinedMenuSelector).slideUp(100).remove()
              theSuggestionLink.hide()
            } else {
              initPredefinedSuggestions()
              theTTdropdownMenu.show()
              $(thePredefinedMenuSelector).slideDown()
            }
          })

          $(searchBoxSelector).on('focus', function (e) {
            if ($(this).val() === '') {
              initPredefinedSuggestions()
              theTTdropdownMenu.show()
            }
          })

          theSuggestionLink.click(function (e) {
            $(this).hide()
            $(theTTdropdownMenu).show()
          })
        }
        // --- end of Predefined suggestions

        // Triger search when a suggestion is selected
        typeaheadEl.bind('typeahead:selected', function () {
          $('#iss-search_form').submit()

          $(searchBoxSelector).blur()
        })
      }
    },
    cancelAjax: function () {
      for (const searchBox of this.searchBoxes) {
        try {
          $(searchBox.selector).typeahead('abortXhr')
        } catch (err) {
          // not the ideal fix, but leaving until typeahead is able to
          // handle abortXhr in all browsers
        }
      }
    },
  }
}(jQuery))
