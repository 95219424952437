
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.isArray || (depth0 && depth0.isArray) || helperMissing).call(depth0, (depth0 != null ? depth0.value : depth0), {"name":"isArray","hash":{},"fn":this.program(2, data),"inverse":this.program(10, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			"
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + ":\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.value : depth0), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.key_value || (depth0 && depth0.key_value) || helperMissing).call(depth0, depth0, {"name":"key_value","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.value : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "							<div class=\"form-group\">\n								<label for=\"changes."
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "\" class=\"col-sm-2 custom-col-sm-5 control-label\">\n									"
    + escapeExpression(((helpers.form2jsLabel || (depth0 && depth0.form2jsLabel) || helperMissing).call(depth0, (depth0 != null ? depth0.key : depth0), {"name":"form2jsLabel","hash":{},"data":data})))
    + "\n								</label>\n								<div class=\"col-sm-7 custom-col-sm-7\">\n";
  stack1 = ((helpers.ifStringLengthExceeds || (depth0 && depth0.ifStringLengthExceeds) || helperMissing).call(depth0, (depth0 != null ? depth0.value : depth0), 1, {"name":"ifStringLengthExceeds","hash":{},"fn":this.program(6, data),"inverse":this.program(8, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "								</div>\n							</div>\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "										<textarea class=\"form-control\" name=\"changes."
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "</textarea>\n";
},"8":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "										<input class=\"form-control\" name=\"changes."
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\">\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			<div class=\"form-group\">\n				<label for\"changes."
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "\"=\"\" class=\"col-sm-2 custom-col-sm-5 control-label\">"
    + escapeExpression(((helpers.form2jsLabel || (depth0 && depth0.form2jsLabel) || helperMissing).call(depth0, (depth0 != null ? depth0.key : depth0), {"name":"form2jsLabel","hash":{},"data":data})))
    + "\n				</label>\n				<div class=\"col-sm-7 custom-col-sm-7\">\n";
  stack1 = ((helpers.ifStringLengthExceeds || (depth0 && depth0.ifStringLengthExceeds) || helperMissing).call(depth0, (depth0 != null ? depth0.value : depth0), 1, {"name":"ifStringLengthExceeds","hash":{},"fn":this.program(11, data),"inverse":this.program(13, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "				</div>\n			</div>\n";
},"11":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "						<textarea class=\"form-control\" name=\"changes."
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "</textarea>\n";
},"13":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "						<input class=\"form-control\" name=\"changes."
    + escapeExpression(((helper = (helper = helpers.key || (depth0 != null ? depth0.key : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"key","hash":{},"data":data}) : helper)))
    + "\" value=\""
    + escapeExpression(((helper = (helper = helpers.value || (depth0 != null ? depth0.value : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"value","hash":{},"data":data}) : helper)))
    + "\">\n";
},"15":function(depth0,helpers,partials,data) {
  return "			<div class=\"col-sm-11 form-buttons btn-group\">\n				<button type=\"submit\" class=\"btn btn-primary submitPUR\"><i class=\"fa fa-search\"></i>Submit</button>\n				<button type=\"button\" class=\"btn btn-primary cancel-pur\">Cancel</button>\n			</div>\n";
  },"17":function(depth0,helpers,partials,data) {
  return "			<div class=\"col-sm-11 custom-col-sm-11 text-right\">\n				<button type=\"submit\" class=\"btn btn-primary btn-lg submitPUR\">Submit</button>\n				<div class=\"pur-spinner\">\n					<span class=\"fa fa-spin fa-refresh\"></span>\n				</div>\n			</div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<form class=\"form-horizontal\" id=\"PURForm\" role=\"form\">\n	<div class=\"form-group\">\n		<label for=\"contact.name\" class=\"col-sm-2 custom-col-sm-5 control-label\">\n			Your name</label>\n		<div class=\"col-sm-7 custom-col-sm-7\">\n			<input class=\"form-control validate-emptiness\" name=\"contact.name\">\n		</div>\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"contact.email\" class=\"col-sm-2 custom-col-sm-5 control-label\">\n			Your email</label>\n		<div class=\"col-sm-7 custom-col-sm-7\">\n			<input class=\"form-control validate-emptiness\" name=\"contact.email\">\n		</div>\n	</div>\n\n	<div class=\"form-group\">\n		<label for=\"contact.phone\" class=\"col-sm-2 custom-col-sm-5 control-label\">\n			Your phone number</label>\n		<div class=\"col-sm-7 custom-col-sm-7\">\n			<input class=\"form-control validate-emptiness\" name=\"contact.phone\">\n		</div>\n	</div>\n\n";
  stack1 = ((helpers.key_value || (depth0 && depth0.key_value) || helperMissing).call(depth0, depth0, {"name":"key_value","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n	<div class=\"form-group\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isMobile : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.program(17, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	</div>\n</form>\n";
},"useData":true});
              Handlebars.templates['src/hbs/partials/pur_form.hbs'] = templateFunction;
              export default templateFunction;
              