
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div id=\"services-search\">\n	<div class=\"row services-search-row\">\n		<div class=\"services-search-row-main\">\n			<span class=\"text-askizzy\">Powering\n				<a class=\"ask-izzy-link\" target=\"_blank\" href=\"https://askizzy.org.au\">Ask Izzy</a>\n			</span>\n			<form role=\"form\" id=\"iss-search_form\">\n				<div class=\"iss-keyword-search\">\n					<label class=\"sr-only\" for=\"iss-keyword-search_input\">Service keywords</label>\n					<input type=\"text\" class=\"search-services-box form-control\" id=\"iss-keyword-search_input\" placeholder=\"Service keywords\" size=\"60\">\n				</div>\n				<div class=\"iss-location-search\">\n					<label class=\"sr-only\" for=\"iss-location-search_input\">Suburb or postcode</label>\n					<input type=\"text\" class=\"search-locations-box form-control\" id=\"iss-location-search_input\" placeholder=\"Suburb or postcode\" size=\"60\">\n				</div>\n				<button type=\"submit\" id=\"iss-search_submit\" class=\"btn btn-primary with-icon\"><i class=\"fa fa-search\"></i>Find services</button>\n\n					"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "spinner.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n\n			</form>\n			<div class=\"help-block\">"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "text_below_search_input.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n			</div>\n		</div><!-- services-search-row-main -->\n\n		<div class=\"services-search-row-auxiliary\">\n			<button class=\"btn btn-primary-dark btn-auxiliary advanced_search\">\n				<div class=\"inner\">\n					<i class=\"fa fa-bullseye\"></i>\n					<div class=\"text\">Advanced<br> Search</div>\n				</div>\n			</button>\n			<button class=\"btn btn-primary-dark btn-auxiliary add-service\">\n				<div class=\"inner\">\n					<i class=\"fa fa-plus\"></i>\n					<div class=\"text\">Add<br> Service</div>\n				</div>\n			</button>\n		</div><!-- services-search-row-auxiliary -->\n	</div>\n	<div class=\"row\">\n		<div id=\"search-sidebar\">\n		</div>\n		<div id=\"leaflet-map\" class=\"col-lg-2\"></div>\n	</div>\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/main.hbs'] = templateFunction;
              export default templateFunction;
              