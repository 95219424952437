
/**
 * EntityListView is the backbone view for displaying the search results.
 * No render() is implemented. Initialize does the 'render', as we always
 * want to render when we initialize.
**/
export function getEntityListViewBase ($) {
  return Backbone.View.extend({
    id: 'entityViewList',
    attributes: {
      class: 'entity-view-list',
    },
    // Return true if the previous view was the service types list
    is_from_service_types_list: function () {
      return ISS.Var.EntityListRequestor !== null &&
        ISS.Var.EntityListRequestor === 'ServiceTypesItem'
    },
    initialize: function (options) {
      this.params = options.params
      var view = this
      var recordsLimit = 20
      var page = 1

      if (typeof view.params.page !== 'undefined' && view.params.page && view.params.page !== 1) {
        // add offset
        view.params.queryObj.offset = Math.ceil(recordsLimit * (view.params.page - 1))
        page = view.params.page
      }

      // Enable search terms highlighting
      view.params.queryObj.highlight = true

      this.collection.fetch({
        data: view.params.queryObj,
        success: function (collection, response) {
          // unpin entities
          ISSgeo.Main.unPinEntities()

          var errorMessage
          if (typeof response !== 'undefined' &&
            typeof response.error_message !== 'undefined') {
            errorMessage = response.error_message
          }

          const templateParams = {
            is_from_service_types_list: view.is_from_service_types_list(),
          }
          $(view.el).append(
            JST[ISS.Var.templatePath + view.templates.list](templateParams),
          )

          // Render out the results collection to the #search-results container
          var template = ''
          collection.each(function (entity) {
            template = JST[ISS.Var.templatePath + view.templates.item]
            if (entity.attributes.crisis) {
              template = JST[ISS.Var.templatePath + view.templates.crisis_item]
            }
            $(view.el).find('#search-results').append(
              ISS.Main.highlightSearchTerms(
                template(jQuery.extend(
                  entity.attributes,
                  { id: entity.id },

                ))))
            // create map marker
            ISSgeo.Main.addEntityMarker(entity)
          })

          view.delegateEvents()

          ISS.Main.appendViewToContainer(view)

          // spinner
          ISS.Main.searchSpinner(false)

          // add entities to map
          if (collection.length > 0) {
            ISSgeo.Main.pinEntities()

            // Show results link if hidden
            // Only for mobile
            if (ISS.Var.isMobile) {
              if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.SearchPanelView)) {
                ISS.ViewInstances.SearchPanelView.toggleViewMapButton(true)
              }
              ISS.Main.displayMobileMap(false)
            }
          }

          if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.collection)) {
            ISS.ViewInstances.EntityListPaginationView.close()
            $(ISS.ViewInstances.ServiceTypesListView.el).hide()
          }

          ISS.ViewInstances.EntityListPaginationView = new ISS.Views.EntityListPaginationView({
            collection: collection,
            page: page,
            error_message: errorMessage,
          })

          // Check to see if we have results.

          if (collection.length > 0) {
            // Remove any previous ServiceTypeListView
            if (ISS.ViewInstances.ServiceTypesListView) {
              $(ISS.ViewInstances.ServiceTypesListView.el).hide()
            }

            var searchString = ''
            if (view.params.simpleSearchString) {
              searchString = view.params.simpleSearchString
            } else {
              searchString = view.params.queryObj.q
            }

            const locationString = view.params.simpleLocationString || view.params.queryObj.area

            ISS.Var.routerMain.navigate(
              'services/results/' + searchString + (
                locationString ? `/${encodeURIComponent(locationString)}` : ''
              ),
              { trigger: false },
            )
            const directoryName = ISS.Var.options.get('overlayDirectoryName')
            if (view.params.queryObj.q) {
              document.title = 'Search for ' + view.params.queryObj.q + ' | ' + directoryName
            } else {
              document.title = 'Search results' + ' | ' + directoryName
            }
            HSNetUtils.history.add()
          } else {
            if (ISS.Var.isMobile) {
              // Hide results link and resize other links
              $('#iss-search_form').addClass('hide-search-results-lnk')

              // No results found
              var messageTemplate = JST[ISS.Var.templatePath + 'no_results_message.hbs']
              ISS.Main.displayGenericModal({ title: 'Search notice' })
              $('.generic-modal .modal-body').html(messageTemplate({ error_message: response.error_message }))
                .find('.fa-info-circle').remove()
              ISS.Main.displayMobileMap(true)
            }
          }

          // Load the location marker based on our results
          if (
            typeof collection.meta.location !== 'undefined' &&
            ISSgeo.Var.userLocation.latitude !== collection.meta.location.lat &&
            ISSgeo.Var.userLocation.longitude !== collection.meta.location.lon
          ) {
            var metaPosition = {
              latitude: collection.meta.location.lat,
              longitude: collection.meta.location.lon,
            }
            ISSgeo.Main.setUserLocationMarker(metaPosition, 'search-term')
          } else {
            ISSgeo.Main.setUserLocationMarker(ISSgeo.Var.userLocation, 'default')
          }

          ISS.Main.showSideBar()
          if (collection.length === 0) {
            $('#search-sidebar').addClass('search-sidebar-no-background')
              .removeClass('search-sidebar-background')
            $('#pagination').css('display', 'none')
          } else {
            $('#search-sidebar').addClass('search-sidebar-background')
              .removeClass('search-sidebar-no-background')
          }

          // Enable tooltip
          $('[data-toggle="tooltip"]').tooltip()

          if (typeof options.successCallback === 'function') {
            options.successCallback(collection)
          }
        },
        error: function (collection, response, options) {
          if (typeof response !== 'undefined') {
            var decoded = JSON.parse(response.responseText)

            if (typeof decoded !== 'undefined') {
              options.success(decoded)
              return
            }

            options.success(response)
          }
        },
      })
    },
    events: {
      'click .entity-details-view-link': 'clickEntityDetailsViewLink',
      'click .phone-link-crisis': 'dialCrisis',
      'click .share-search': 'shareSearch',
    },
    shareSearch: function (e) {
      e.preventDefault()

      const model = {
        description: 'Search results from ' + ISS.Var.options.get('overlayDirectoryName'),
      }
      const shareModal = new ISS.Views.ShareModalView({ model: model })
      $('body').append(shareModal.el)
      shareModal.$el.modal('show')
    },
    dialCrisis: function (eventObject) {
      var phoneNumber = $(eventObject.currentTarget).data('crisis-number')
      window.open('tel:' + phoneNumber)
      return false
    },
    /**
     * clickEntityDetailsViewLink() handles the click that brings up the
     * EntityDetailsView for an entity.
    **/
    clickEntityDetailsViewLink: function (eventObject) {
      ISS.Var.detailsRequestor = 'results'
      ISS.Main.displayEntityDetails(eventObject)
      return false
    },
  })
}

export function getEntityListViewMobile ($) {
  return ISS.Views.EntityListViewBase.extend({
    templates: {
      list: 'entity_list_mobile.hbs',
      item: 'entity_list_item_mobile.hbs',
      crisis_item: 'entity_list_item_crisis.hbs',
    },
    events: {
      'click .entity-details-view-link': 'clickEntityDetailsViewLink',
      'click .phone-link-crisis': 'dialCrisis',
      'click .backToServiceTypeListLink': 'backToServiceTypeList',
    },

    /**
     * Call the crisis phone number.
     **/
    dialCrisis: function (e) {
      var phoneNumber = $(e.currentTarget).data('crisis-number')
      window.open('tel:' + phoneNumber)
      return false
    },
  })
}

export function getEntityListView ($) {
  return ISS.Views.EntityListViewBase.extend({

    templates: {
      list: 'entity_list.hbs',
      item: 'entity_list_item.hbs',
      crisis_item: 'entity_list_item_crisis.hbs',
    },

    events: {
      'click .entity-details-view-link': 'clickEntityDetailsViewLink',
      'click .share-search': 'shareSearch',
    },

  })
}
