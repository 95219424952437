/**
 * Main view to attach all events related to the search panel
**/

export function getSearchPanelViewBase ($) {
  return Backbone.View.extend({
    render: function () {
      var template = JST[ISS.Var.templatePath + this.templates.main]
      this.$el.append(template({
        directoryRegion: ISS.Var.options.get('directoryRegion'),
        searchFieldPlaceholder: ISS.Var.options.get('searchFieldPlaceholder'),
      }))

      ISS.Suggestions.init()

      return this
    },
    searchForInput_validate: function () {
      // Conditionally validate searchForInput field
      // If service type and either area or physical location is supplied
      // then searchForInput is not required
      var serviceTypeInput = $('#serviceTypeInput').val()

      var locationInput = $('#locationInput').val()
      if (serviceTypeInput !== '' && locationInput !== '') {
        $('#searchForInput').toggleClass('validate-emptiness', false)
      } else {
        $('#searchForInput').toggleClass('validate-emptiness', true)
      }
      HSNetUtils.validateField($('#searchForInput'))
    },
    refreshServiceTypePicker: function () {
      $('#serviceTypeInput').selectpicker('refresh')
    },
    advancedSearch_submit: function () {
      ISS.ViewInstances.SearchPanelView.searchForInput_validate()
      // If this search was submitted while hidden, then it was initiated
      // from the service types list. Otherwise its a manual advanced search.
      if (ISS.ViewInstances.SearchPanelView.isAdvancedSearchVisible()) {
        ISS.Var.EntityListRequestor = 'SearchPanelViewBase'
      } else {
        ISS.Var.EntityListRequestor = 'ServiceTypesItem'
      }
    },
    search: function (e) {
      // Prevent submit form from actually submitting
      e.stopPropagation()
      e.preventDefault()

      // if we are searching from the service types list view
      // and the query is the same, we don't need to process again
      // MAYBE fine to process again if the data is cached (otherwise its hard for
      // the mobile view which always re-queries)
      if (HSNetUtils.history.currentState() &&
        HSNetUtils.history.currentState().endsWith('/#services/servicetypes/' +
          encodeURIComponent($('#iss-keyword-search_input').val()))
      ) { return }

      ISS.Var.EntityListRequestor = 'SearchPanelViewBase'
      ISS.Var.simpleSearch = true
      ISS.Main.submitSearch()

      // cancelAjax does two things here:
      // Nullifies the timeout on keypresses in the search field
      // Kills any XHRs that have already been fire off
      ISS.Suggestions.cancelAjax()
      // Hides the drop-down if it is already visible.
      $('.tt-dropdown-menu').css('display', 'none')

      return false
    },
    advancedSearch: function () {
      ISS.Var.simpleSearch = false
      $('#issAdvancedSearchForm').submit(ISS.Main.submitSearch)

      // typeahead auto-complete
      $('#spokenLanguagesInput').typeahead({
        remote: {
          url: ISS.Main.makeIssUrl(
            'suggest/',
            {
              start: '%QUERY',
              type: 'language',
            },
          ),
          filter: function (data) {
            return data.objects
          },
        },
        limit: 10,
      })

      var serviceTypeInput = $('#serviceTypeInput')

      var renderOption = function (item, level) {
        // load options here
        var indentPadding = 20 // px
        var $option = $('<option>')

        var optionText = ''

        if (level > 1) {
          optionText = optionText + window.HSNetUtils.htmlDecode('&boxur;&nbsp;')
        }
        optionText = optionText + ' ' + item.value

        $option
          .val(item.value)
          .text(optionText)
          .css('padding-left', (indentPadding * level).toString() + 'px')

        // Before adding the option, check if it already exists
        // Remove the old one, add the new one, set value again.
        var preAddedOption = $('#serviceTypeInput option[value="' + window.HSNetUtils.htmlDecode(item.value) + '"]')
        var preAddedVal = ''
        if (preAddedOption.length > 0) {
          preAddedVal = preAddedOption.val()
          preAddedOption.remove()
        }
        $(serviceTypeInput).append($option)
        // Retain old value and selected option
        if (preAddedVal === $option.val()) {
          $(serviceTypeInput).val($option.val()).change()
        }

        // render children
        if (item.children && item.children.length > 0) {
          item.children.forEach(function (childItem) {
            renderOption(childItem, level + 1)
          })
        }
      }

      // load service types collection
      var serviceTypesCollection = new ISS.Models.ServiceTypeCollection()
      serviceTypesCollection.fetch({
        success: function (serviceTypesCollection, response) {
          serviceTypesCollection.each(function (item, index, all) {
            renderOption(item.attributes, 1)
          })
          ISS.ViewInstances.SearchPanelView.refreshServiceTypePicker()
        },
      })
    },
    renderAdvancedSearch: function (keepModalHidden) {
      if (!document.getElementById('advancedSearchModal')) {
        var template = JST[ISS.Var.templatePath + this.templates.advancedSearch]
        $('#services-search').append(template({
          directoryRegion: ISS.Var.options.get('directoryRegion'),
          advancedSearchHelpUrl: ISS.Var.options.get('advancedSearchHelpUrl'),
        }))
        // set submit handler for conditional validation
        this.$el.find('#serviceTypeInput').change(this.searchForInput_validate)
        this.$el.find('#locationInput').change(this.searchForInput_validate)
        this.$el.find('#issAdvancedSearchForm').submit(this.advancedSearch_submit)
        this.advancedSearch()
      }
      if (keepModalHidden !== null && keepModalHidden === true) {
        $('#advancedSearchModal').modal('hide')
      } else {
        $('#advancedSearchModal').modal('show')
      }
    },
    isAdvancedSearchVisible: function () {
      return $('#advancedSearchModal').is(':visible')
    },
    openAdvancedSearch: function () {
      ISS.Var.routerMain.navigate('services/advanced-search', { trigger: true })
      return false
    },
    renderAddServiceForm: function () {
      if (!ISS.ViewInstances.AddServiceView) {
        ISS.ViewInstances.AddServiceView = ISS.Var.routerMain._viewFactory.createAddServiceView()
      }

      ISS.ViewInstances.AddServiceView.render()

      return false
    },
    remove: function () {
      this.$el.children().remove()
      this.stopListening()
      return this
    },
    removeErrorMessage: function (event) {
      HSNetUtils.validateField($(event.currentTarget))
    },
    clearAdvancedSearchForm: function () {
      var sitesOnly = document.querySelector('#sitesOnly').checked
      $('#issAdvancedSearchForm')[0].reset()
      // After resetting the form, the serviceTypeInput selectpicker needs to be refreshed
      ISS.ViewInstances.SearchPanelView.refreshServiceTypePicker()
      document.querySelector('#sitesOnly').checked = sitesOnly
      // Set default values
      this.toggleSitesOnly()
    },
    clearSearchInputValidationMsg: function () {
      $('#issAdvancedSearchForm div').removeClass('has-error custom-field-error')
      $('#issAdvancedSearchForm div.validation-message').remove()
    },
    toggleSitesOnly: function () {
      this.clearSearchInputValidationMsg()
      var sitesOnly = document.querySelector('#sitesOnly').checked
      if (sitesOnly) {
        // Default word type filter to "Any words"
        $('#wordFilterTypeSelect option:contains("Any words")').prop('selected', true)
        $('#wordFilterTypeSelect option:not(:contains("Any words"))').prop('disabled', true).prop('selected', false)
        $('#wordFilterTypeSelect').parent().hide()

        // Re-label Search for to Site Name
        $('label[for="wordFilterTypeSelect"]').prop('innerText', 'Site Name')
          .removeClass('col-sm-2 custom-col-sm-2')
          .addClass('col-sm-5 custom-col-sm-5')

        // Update site only input validation message
        $('#searchForInput').data('validation-message', 'Please enter a valid site name')

        // Hide/ Disable excluded words input
        $('#excludedWordsInput').prop('readonly', true).val('').closest('.form-group').hide()

        // Hide/ Disable referral enabled only
        $('#referralEnabledOnly').prop('readonly', true).prop('checked', false).closest('.form-group').hide()

        // Default gender to any gender (ie do not use for sites)
        $('#genderSelect option:contains("Any gender")').prop('selected', true)
        $('#genderSelect option:not(:contains("Any gender"))').prop('disabled', true).prop('selected', false)
        $('#genderSelect').closest('.form-group').hide()

        // Default specfic to 'please select'
        $('#specificSelect option:contains("Please select")').prop('selected', true)
        $('#specificSelect option:not(:contains("Please select"))').prop('disabled', true).prop('selected', false)
        $('#specificSelect').closest('.form-group').hide()

        // Hide / Default spoken language
        $('#spokenLanguagesInput').prop('readonly', true).val('').closest('.form-group').hide()

        // Default age demographic to "Any Age" (ie do not use)
        $('#serviceForSelect option:contains("Any age")').prop('selected', true)
        $('#serviceForSelect option:not(:contains("Any age"))').prop('disabled', true).prop('selected', false)
        $('#serviceForSelect').closest('.form-group').hide()

        // Default to checked, but do not disable/hide
        $('#catchmentCheck').prop('checked', true)

        // Reset service type to nothing
        $('#serviceTypeInput option[value=""').prop('selected', true)
        ISS.ViewInstances.SearchPanelView.refreshServiceTypePicker()
        $('#serviceTypeInput').closest('.form-group').hide()

        // Rename submit button to reflect site search
        $('.submit-advanced-search').prop('innerText', 'Search sites')
      } else {
        // Set default options for advanced services search
        $('#wordFilterTypeSelect option').prop('disabled', false)
        $('#wordFilterTypeSelect').parent().show()
        $('label[for="wordFilterTypeSelect"]').prop('innerText', 'Search for')
          .addClass('col-sm-2 custom-col-sm-2')
          .removeClass('col-sm-5 custom-col-sm-5')
        $('#searchForInput').data('validation-message', 'Please enter a valid service or keyword')

        $('#excludedWordsInput').prop('readonly', false).closest('.form-group').show()
        $('#referralEnabledOnly').prop('readonly', false).closest('.form-group').show()
        $('#genderSelect option').prop('disabled', false)
        $('#genderSelect').closest('.form-group').show()
        $('#specificSelect option').prop('disabled', false)
        $('#specificSelect').closest('.form-group').show()
        $('#spokenLanguagesInput').prop('readonly', false).closest('.form-group').show()
        $('#serviceForSelect option').prop('disabled', false)
        $('#serviceForSelect').closest('.form-group').show()
        $('#catchmentCheck').prop('checked', true)
        $('#serviceTypeInput').closest('.form-group').show()
        $('.submit-advanced-search').prop('innerText', 'Search services')
      }
    },
  })
}

export function getSearchPanelViewMobile ($) {
  return ISS.Views.SearchPanelViewBase.extend({
    templates: {
      main: 'main-mobile.hbs',
      advancedSearch: 'advanced_search_mobile.hbs',
    },
    el: '#service-directory-container',
    events: {
      'submit #iss-search_form': 'search',
      'click #issAdvancedSearchMobilelnk': 'openAdvancedSearch',
      'click #issSearchResultsMobileLnk': 'showSearchResults',
      'click #backFromAdvancedSearch': 'backToMenu',
      'click .cancel-advanced-search': 'backToMenu',
      'blur #searchForInput': 'removeErrorMessage',
      'click .clear-advanced-search': 'clearAdvancedSearchForm',
      'change input#sitesOnly': 'toggleSitesOnly',
    },
    initialize: function () {
      // hide header & footer
      $('.main-header').hide()
      $('#footer').hide()
      $('#wrap').removeClass('wrap-default-height')
    },
    showSearchResults: function () {
      if (window.location.hash.indexOf('/map') === -1) {
        // show map search results
        ISS.Var.routerMain.navigate('services/results/map', { trigger: true })
      } else {
        ISS.Var.routerMain.navigate('services/results', { trigger: true })
      }
      return false
    },
    renderAdvancedSearch: function (keepModalHidden) {
      $('#services-search').hide()
      $('.search-result-container').hide()
      $('#mapMobileContainer').hide()

      var $advancedSearchMobileContainer = $('#advancedSearchMobileContainer')
      if ($advancedSearchMobileContainer.length === 0) {
        var template = JST[ISS.Var.templatePath + this.templates.advancedSearch]
        $('#service-directory-container').append(template({
          isMobile: ISS.Var.isMobile,
          directoryRegion: ISS.Var.options.get('directoryRegion'),
        }))
        // set submit handler for conditional validation
        this.$el.find('#issAdvancedSearchForm').submit(this.advancedSearch_submit)

        this.advancedSearch()
      }
      if (keepModalHidden !== null && keepModalHidden === true) {
        $('#advancedSearchMobileContainer').hide()
      } else {
        $('#advancedSearchMobileContainer').show()
      }
    },
    isAdvancedSearchVisible: function () {
      return $('#advancedSearchMobileContainer').is(':visible')
    },
    backToMenu: function () {
      ISS.Var.routerMain.navigate('services/results', { trigger: true })
      ISSgeo.Main.resizeMap()
      return false
    },
    toggleViewMapButton: function (show) {
      if (show) {
        $('#iss-search_form').removeClass('hide-search-results-lnk')
      } else {
        $('#iss-search_form').addClass('hide-search-results-lnk')
      }
    },
  })
}

export function getSearchPanelView ($) {
  return ISS.Views.SearchPanelViewBase.extend({
    templates: {
      main: 'main.hbs',
      advancedSearch: 'advanced_search.hbs',
    },
    el: '#service-directory-container',
    events: {
      'submit #iss-search_form': 'search',
      'click button.advanced_search': 'renderAdvancedSearch',
      'blur #searchForInput': 'removeErrorMessage',
      'click .clear-advanced-search': 'clearAdvancedSearchForm',
      'click .add-service': 'renderAddServiceForm',
      'change input#sitesOnly': 'toggleSitesOnly',
    },
    toggleViewMapButton: function (show) {
      // Overriden in mobile view
      return false
    },
  })
}
