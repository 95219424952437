
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "		"
    + escapeExpression(((helper = (helper = helpers.error_message || (depth0 != null ? depth0.error_message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"error_message","hash":{},"data":data}) : helper)))
    + "\n		<br><br>\n		Please modify your query and try again.\n	</p>\n";
},"3":function(depth0,helpers,partials,data) {
  return "    	No results match your query. Please modify your query and try again.<p></p>\n	    <p>Tips:</p>\n	    <ul>\n	        <li>Check your words are not misspelt.</li>\n	        <li>Use different search terms.</li>\n	        <li>Use more general search terms.</li>\n	        <li>Use less search terms.</li>\n	    </ul>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"no-results-container\">\n    <i class=\"fa fa-info-circle\"></i>\n    <p>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.error_message : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/partials/no_results_message.hbs'] = templateFunction;
              export default templateFunction;
              