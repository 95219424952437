export function getEntityListPaginationView ($) {
  return Backbone.View.extend({
    templateTotal: function () {
      return JST[ISS.Var.templatePath + 'entity_list_total.hbs']
    },
    initialize: function (params) {
      var meta = this.collection.meta
      var totalPages = Math.ceil(meta.available_count / meta.limit)
      var currentPage = params.page || 1

      meta.numberOfResults = this.collection.length

      if (params.error_message) {
        var messageTemplate = JST[ISS.Var.templatePath + 'no_results_message.hbs']
        $('#totalRecords_container').html(messageTemplate({ error_message: params.error_message }))
        return
      } else {
        $('#totalRecords_container').html(this.templateTotal()(meta))
      }

      var options = {
        currentPage: currentPage,
        totalPages: totalPages,
        numberOfPages: totalPages,
        bootstrapMajorVersion: 3,
        pageUrl: function () {
          /* jshint ignore:start */
          return 'javascript:;'
          /* jshint ignore:end */
        },
        shouldShowPage: function (type, page, current) {
          switch (type) {
            case 'first':
            case 'last':
              return false
            case 'next':
              return (current !== this.totalPages)
            case 'prev':
              return (current !== 1)
            default:
              return true
          }
        },
        itemTexts: function (type, page, current) {
          switch (type) {
            case 'prev':
              return '&laquo;'
            case 'next':
              return '&raquo;'
            case 'page':
              return page
          }
        },
        onPageClicked: function (e, originalEvent, type, page) {
          ISS.Main.submitSearch({ page: page })
        },
      }

      if (meta.available_count !== 0) {
        $('#pagination').find('ul').bootstrapPaginator(options)
      } else {
        $('#pagination').find('ul').html('')
      }
      return this
    },
  })
}
