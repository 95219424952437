import { getInitialISSContents } from './Main'
import { getMainRouter } from './MainRouter'
import { setupViewFactories } from './ViewFactories'
import { getEntityCollection } from './collections/EntityCollection'
import { getServiceTypeCollection } from './collections/ServiceTypeCollection'
import { getServiceCountHelper } from './helpers'
import { iosZoomFix } from './iosZoomFix'
import { getEntityModel } from './models/EntityModel'
import { getOptions } from './optionsLoader'
import { polyfillEndsWith, polyfillIncludes } from './polyfills'
import { getEntityDetailsMapView } from './views/EntityDetailsMapViews'
import { getEntityDetailsView, getEntityDetailsViewBase, getEntityDetailsViewMobile } from './views/EntityDetailsView'
import { getEntityListView, getEntityListViewBase, getEntityListViewMobile } from './views/EntityListView'
import { getRelatedEntitiesView } from './views/RelatedEntitiesView'
import { getSearchPanelViewBase, getSearchPanelViewMobile, getSearchPanelView } from './views/SearchPanelView'
import { getAddServiceView } from './views/AddServiceView'
import { getHomeViewBase, getOriginView } from './views/HomeView'
import { getServiceTypesItem, getServiceTypesListView } from './views/ServiceTypesView'
import { getShareModalView } from './views/ShareModelView'
import { getEntityListPaginationView } from './views/EntityListPaginationView'
import { getPURViewModal } from './views/PURView'
import { getSiteEntity } from './models/SiteEntityModel'
import { getServiceEntity } from './models/ServiceEntityModel'

(function ($) {
  'use strict'

  iosZoomFix()

  // Load polyfills
  polyfillEndsWith()
  polyfillIncludes()

  /**
   * window.ISS is the namespace for the ISS Frontend app.

   * It contains the following:
   * Models -- backbone Models
   * Views  -- backbone Views
   * Main   -- functions
  **/
  window.ISS = getInitialISSContents($)

  ISS.listScrollPos = 0

  ISS.Views.SearchPanelViewBase = getSearchPanelViewBase($)
  ISS.Views.SearchPanelViewMobile = getSearchPanelViewMobile($)
  ISS.Views.SearchPanelView = getSearchPanelView($)

  ISS.Views.AddServiceView = getAddServiceView($)

  ISS.Views.HomeViewBase = getHomeViewBase($)
  ISS.Views.OriginView = getOriginView($)

  ISS.Views.EntityListViewBase = getEntityListViewBase($)
  ISS.Views.EntityListViewMobile = getEntityListViewMobile($)
  ISS.Views.EntityListView = getEntityListView($)

  ISS.Views.EntityListPaginationView = getEntityListPaginationView($)

  ISS.Views.EntityDetailsViewBase = getEntityDetailsViewBase($)
  ISS.Views.EntityDetailsViewMobile = getEntityDetailsViewMobile($)
  ISS.Views.EntityDetailsView = getEntityDetailsView($)

  ISS.Views.ServiceTypesItem = getServiceTypesItem($)
  ISS.Views.ServiceTypesListView = getServiceTypesListView($)

  ISS.Views.RelatedEntitiesView = getRelatedEntitiesView($)

  ISS.Views.EntityDetailsMapView = getEntityDetailsMapView($)

  ISS.Views.ShareModalView = getShareModalView($)

  ISS.Views.PURViewModal = getPURViewModal($)

  ISS.Models.EntityModel = getEntityModel($)

  ISS.Models.SiteEntity = getSiteEntity($)

  ISS.Models.ServiceEntity = getServiceEntity($)

  ISS.Models.EntityCollection = getEntityCollection($)

  ISS.Models.ServiceTypeCollection = getServiceTypeCollection($)

  setupViewFactories($)

  ISS.Routers.Main = getMainRouter($)

  ISS.ServiceCountHelper = getServiceCountHelper($)

  ISS.Options = getOptions($)

  $(document).ready(function () {
    ISS.Var.isMobile = HSNetUtils.isMobileDevice()

    var viewFactory
    if (ISS.Var.isMobile) {
      viewFactory = new ISS.Factories.PhoneViewsFactory()
    } else {
      viewFactory = new ISS.Factories.DesktopViewsFactory()
      $('body').addClass('is-desktop')
    }

    ISS.Var.routerMain = new ISS.Routers.Main({ viewFactory: viewFactory })

    ISSgeo.Main.getUserCurrentLocation()

    Backbone.history.start({ pushState: false })
  })
})(jQuery)
