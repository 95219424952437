
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Organisation Name</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.site : depth0)) != null ? stack1.organisation : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Site Name</dt>\n						<dd class=\"col-xs-12 col-md-8\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.site : depth0)) != null ? stack1.name : stack1), depth0))
    + "</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Description</dt>\n						<dd class=\"col-xs-12 col-md-8\"> ";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + " </dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"7":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">ABN</dt>\n						<dd class=\"col-xs-12 col-md-8\">"
    + escapeExpression(((helper = (helper = helpers.abn || (depth0 != null ? depth0.abn : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"abn","hash":{},"data":data}) : helper)))
    + "</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Eligibility</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.eligibility_info : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Ineligibility</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.ineligibility_info : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"13":function(depth0,helpers,partials,data) {
  return "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">NDIS Approved</dt>\n						<dd class=\"col-xs-12 col-md-8\">Yes</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
  },"15":function(depth0,helpers,partials,data) {
  return "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">\n							Accepts Health Care Cards\n						</dt>\n						<dd class=\"col-xs-12 col-md-8\">Yes</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
  },"17":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Availability</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.availability : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"19":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Catchment Area</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.catchment : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"21":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Cost</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.cost : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"23":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Billing method</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.billing_method : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"25":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Referral</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.referral_info : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"27":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Assessment</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.assessment_criteria : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"29":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Special Requirements</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.special_requirements : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"31":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n							<dt class=\"col-xs-12 col-md-4\">\n								Intake information\n							</dt>\n							<dd class=\"col-xs-12 col-md-8\">\n								";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.intake_info : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n							</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"33":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "					<div class=\"directory-details-info-row\">\n							<dt class=\"col-xs-12 col-md-4\">Intake point</dt>\n							<dd class=\"col-xs-12 col-md-8\">\n								";
  stack1 = ((helpers.linkUrls || (depth0 && depth0.linkUrls) || helperMissing).call(depth0, ((helpers.formatLineBreaks || (depth0 && depth0.formatLineBreaks) || helperMissing).call(depth0, (depth0 != null ? depth0.intake_point : depth0), {"name":"formatLineBreaks","hash":{},"data":data})), {"name":"linkUrls","hash":{},"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n							</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"35":function(depth0,helpers,partials,data) {
  var stack1, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Spoken Languages</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n							English";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.languages : depth0), {"name":"each","hash":{},"fn":this.program(36, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"36":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return ", "
    + escapeExpression(lambda(depth0, depth0));
},"38":function(depth0,helpers,partials,data) {
  var stack1, buffer = "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Accreditations</dt>\n						<dd class=\"col-xs-12 col-md-8\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.accreditation : depth0), {"name":"each","hash":{},"fn":this.program(39, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "						</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"39":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "								"
    + escapeExpression(lambda(depth0, depth0))
    + "<br>\n";
},"41":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Funding body</dt>\n						<dd class=\"col-xs-12 col-md-8\">"
    + escapeExpression(((helper = (helper = helpers.funding_body || (depth0 != null ? depth0.funding_body : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"funding_body","hash":{},"data":data}) : helper)))
    + "</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"43":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "					<div class=\"directory-details-info-row\">\n						<dt class=\"col-xs-12 col-md-4\">Provider Type</dt>\n						<dd class=\"col-xs-12 col-md-8\">"
    + escapeExpression(((helper = (helper = helpers.provider_type || (depth0 != null ? depth0.provider_type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"provider_type","hash":{},"data":data}) : helper)))
    + "</dd>\n						<div class=\"clearfix\"></div>\n					</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"directory-details-body\">\n\n	<ul id=\"directory-details-body-tabs\" class=\"directory-details-body-tabs row nav nav-pills\">\n		<li class=\"active col-xs-4\">\n			<a href=\"#information-tab\" data-toggle=\"tab\">Information</a>\n		</li>\n		<li class=\"col-xs-4\">\n			<a href=\"#contact-tab\" data-toggle=\"tab\">Contact</a>\n		</li>\n		<li class=\"col-xs-4\">\n				<a href=\"#visit-tab\" data-toggle=\"tab\">Visit</a>\n			</li>\n	</ul>\n\n	<div class=\"tab-content\">\n		<div id=\"information-tab\" class=\"directory-details-service tab-pane active\">\n			<h3 class=\"visible-print\">Information</h3>\n			<dl class=\"row\">\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.site : depth0)) != null ? stack1.organisation : stack1)) != null ? stack1.name : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.site : depth0)) != null ? stack1.name : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.description : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.abn : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.eligibility_info : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ineligibility_info : depth0), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ndis_approved : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.healthcare_card_holders : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.availability : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.catchment : depth0), {"name":"if","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.cost : depth0), {"name":"if","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.billing_method : depth0), {"name":"if","hash":{},"fn":this.program(23, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.referral_info : depth0), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.assessment_criteria : depth0), {"name":"if","hash":{},"fn":this.program(27, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.special_requirements : depth0), {"name":"if","hash":{},"fn":this.program(29, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.intake_info : depth0), {"name":"if","hash":{},"fn":this.program(31, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.intake_point : depth0), {"name":"if","hash":{},"fn":this.program(33, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.languages : depth0), {"name":"if","hash":{},"fn":this.program(35, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.accreditation : depth0), {"name":"if","hash":{},"fn":this.program(38, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.funding_body : depth0), {"name":"if","hash":{},"fn":this.program(41, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.provider_type : depth0), {"name":"if","hash":{},"fn":this.program(43, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n				<div id=\"relatedEntitiesContainer\" class=\"related-entities\">\n					<span class=\"fa fa-refresh fa-spin related-entity-spinner\">\n					</span>\n				</div>\n			</dl>\n		</div>\n\n		"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "entity_visit_us.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n		"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "entity_contact_us.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n	</div>\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/partials/entity_details.hbs'] = templateFunction;
              export default templateFunction;
              