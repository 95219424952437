
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"info-container\">\n	<i class=\"fa fa-info-circle\"></i>\n</div>\n<p class=\"map-message\">\n	The "
    + escapeExpression(((helper = (helper = helpers.directoryName || (depth0 != null ? depth0.directoryName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"directoryName","hash":{},"data":data}) : helper)))
    + " is a comprehensive online list of\n	services in "
    + escapeExpression(((helper = (helper = helpers.directoryRegion || (depth0 != null ? depth0.directoryRegion : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"directoryRegion","hash":{},"data":data}) : helper)))
    + ", listing\n	detailed information about organisations across a variety of sectors\n	including health, welfare, community,\n	education, disability, aged care, legal, housing and more.\n</p>\n";
},"useData":true});
              Handlebars.templates['src/hbs/map_info_overlay.hbs'] = templateFunction;
              export default templateFunction;
              