
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "entity_controls.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n\n"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "entity_details.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n\n";
},"useData":true});
              Handlebars.templates['src/hbs/details.hbs'] = templateFunction;
              export default templateFunction;
              