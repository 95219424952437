/**
 * EntityDetailsView a detail view for the entity. Handling the four
 * entity types (org, site, service, practitioner).
**/
export function getEntityDetailsViewBase ($) {
  return Backbone.View.extend({
    initialize: function () {
      var attributes = this.model.attributes
      var tpl = JST[ISS.Var.templatePath + this.template]

      $(this.el).append(
        ISS.Main.highlightSearchTerms(
          tpl(jQuery.extend(
            attributes,
            {
              id: this.model.id,
              isMobile: ISS.Var.isMobile,
              requestor: ISS.Var.detailsRequestor,
            },
          ))))

      this.delegateEvents()

      // Related entities
      if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.RelatedEntitiesView)) {
        ISS.ViewInstances.RelatedEntitiesView.close()
      }
      ISS.ViewInstances.RelatedEntitiesView = new ISS.Views.RelatedEntitiesView(attributes)
    },

    events: {
      'click .backToResultsListLink': 'clickBackToResultsListLink',
    },

    /**
     * clickBackToResultsListLink()
     * Handles displaying the entity list when the user is on the details view.
     * Assumes that the entity list is already in the cache.
     **/
    clickBackToResultsListLink: function () {
      if (ISS.ViewInstances.entityListView) {
        if (HSNetUtils.history.getPreviousState().startsWith('/#services/results')) {
          window.history.back()
        } else {
          ISS.Var.routerMain.navigate('services/results', { trigger: true })
        }
      } else {
        ISS.Var.routerMain.navigate('services', { trigger: true })
      }
      return false
    },
    clickAdhcToggle: function (e) {
      e.preventDefault()

      $('#adhc').collapse('toggle')
    },
    clickOpenOpeningHoursComments: function (e) {
      var targetAttr = e.currentTarget.attributes
      var params = {
        title: 'Opening Hours',
        content: 'More information about opening hours on ' + targetAttr['data-oh-day'].value +
              '<br /><br />' + targetAttr['data-oh-note'].value,
      }
      if ($('#genericModal').length) {
        $('#genericModal').remove()
      }
      ISS.Main.displayGenericModal(params)
    },
  })
}

export function getEntityDetailsViewMobile ($) {
  return ISS.Views.EntityDetailsViewBase.extend({

    template: 'details_mobile.hbs',

    events: {
      'click #backFromEntityDetails': 'backToMenu',
      'click .adhcToggle': 'clickAdhcToggle',
      'click #openingHoursInfo': 'clickOpenOpeningHoursComments',
    },

    backToMenu: function () {
      ISS.Var.routerMain.navigate('services/results', { trigger: true })
      return false
    },
  })
}

export function getEntityDetailsView ($) {
  return ISS.Views.EntityDetailsViewBase.extend({
    template: 'details.hbs',
    events: {
      'click #openPUR': 'openPURModal',
      'click #openingHoursInfo': 'clickOpenOpeningHoursComments',
      'click .adhcToggle': 'clickAdhcToggle',
      'click .backToResultsListLink': 'clickBackToResultsListLink',
      'click .openShareModal': 'openShareModal',
    },
    openPURModal: function () {
      var purView = new ISS.Views.PURViewModal({ model: this.model })
      $('#PURForm').remove() // ugly but ok for now.
      $('#services-search').append(purView.el)
      $('.form-control[name="changes.description"]').val('') // temp fix
      purView.$el.modal('show')
    },
    openShareModal: function (e) {
      e.preventDefault()
      const model = {
        entity: this.model.attributes,
        description: encodeURIComponent(this.model.attributes.name),
      }
      const shareModal = new ISS.Views.ShareModalView({ model: model })
      $('body').append(shareModal.el)
      shareModal.$el.modal('show')
    },
  })
}
