
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.site : depth0)) != null ? stack1.name : stack1), depth0))
    + "\n";
},"3":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "					"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\n";
},"5":function(depth0,helpers,partials,data) {
  return "				<div class=\"now-open-crisis\">\n					<i class=\"fa fa-clock-o\" title=\"Clock icon\"></i>Now open\n				</div>\n";
  },"7":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "					<span class=\"phone-link phone-link-crisis\" data-crisis-number=\""
    + escapeExpression(((helpers.onlyNumbers || (depth0 && depth0.onlyNumbers) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.phones : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.number : stack1), {"name":"onlyNumbers","hash":{},"data":data})))
    + "\">\n						<i class=\"fa fa-phone\" title=\"Phone icon\"></i>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.phones : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.number : stack1), depth0))
    + "\n					</span>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<a data-id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\"#services/"
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "/"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"entity-list-item panel panel-default crisis\">\n	<div class=\"panel-heading crisis-heading\">\n		<div class=\"row\">\n			<div class=\"col-xs-8 crisis-name\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.site : depth0)) != null ? stack1.name : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "			</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.now_open : depth0)) != null ? stack1.now_open : stack1), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "		</div>\n		<div class=\"row more-info-row\">\n			<div class=\"col-xs-8\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.phones : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "			</div>\n			<div class=\"col-xs-4 text-right\">\n				<span class=\"entity-details-view-link faux-link\">\n					More information &gt;\n				</span>\n			</div>\n		</div>\n	</div>\n</a>\n";
},"useData":true});
              Handlebars.templates['src/hbs/entity_list_item_crisis.hbs'] = templateFunction;
              export default templateFunction;
              