// Helper for loading service counts for service types.
export function getServiceCountHelper ($) {
  return {
    _service_count_pending_queue: {},
    scroll_load_pending: false,

    // Make call to API to get service type count.
    getServiceCount: function (badgeElement) {
      if ($(badgeElement).length > 0) {
        var area = $(badgeElement).data('st-area')
        var serviceTypeId = $(badgeElement).data('st-id')
        var url = ISS.Main.makeIssUrl('servicetypes/' + serviceTypeId + '/service-counts/', {}, '')

        var data = {}
        var postcodeRegex = new RegExp(HSNetUtils.postcode_regex)
        if (String(area).match(postcodeRegex)) {
          data.postcode = area
        } else {
          data.suburb = area
        }

        // Queue an api call if the same request isn't already queued and pending.
        if (!(badgeElement in ISS.ServiceCountHelper._service_count_pending_queue)) {
          var apiRequest = $.ajax({
            url: url,
            type: 'GET',
            data: data,
          })
            .done(function (result) {
            // Remove from api pending list.
              delete ISS.ServiceCountHelper._service_count_pending_queue[badgeElement]
              $(badgeElement).closest('.list-group-item').data('st-count-loaded', true)

              // Set display data.
              $(badgeElement).find('#count_value').text(result.service_counts)
              if (!isNaN(result.service_counts) && Number(result.service_counts) > 0) {
                $(badgeElement).removeClass('no-services')
              } else {
                $(badgeElement).addClass('no-services')
              }

              // Set map icon
              // We only know the location after retrieving a service type count.
              ISS.ViewInstances.ServiceTypesListView.updateMapMarker(
                result.location.lat, result.location.lon)
            })
          // Add to api pending list.
          ISS.ServiceCountHelper._service_count_pending_queue[badgeElement] = apiRequest
        }
      }
    },

    // Get and populate Service Type count badger.
    populateServiceCount: function (badgeSelector) {
      ISS.ServiceCountHelper.getServiceCount(badgeSelector)
    },

    // Item_list: list of "list-group-item".
    load_children_st_counts: function (itemList) {
      // Queue up the counts for current children.
      $(itemList).each(function (index, item) {
        // If the item is in the viewport and data not loaded.
        if (HSNetUtils.isInViewport(item) &&
          $(this).data('st-count-loaded') !== true) {
          // Pass badge selector.
          ISS.ServiceCountHelper.populateServiceCount(
            '#st-count-' + $(this).data('service-type-id'),
          )
        }
      })
    },
  }
}
