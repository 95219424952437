
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"entity-list-totals row\">\n            <div class=\"col-xs-6\">\n                <p>\n                Displaying\n                "
    + escapeExpression(((helper = (helper = helpers.available_count || (depth0 != null ? depth0.available_count : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"available_count","hash":{},"data":data}) : helper)))
    + "\n                "
    + escapeExpression(((helpers.pluralize || (depth0 && depth0.pluralize) || helperMissing).call(depth0, (depth0 != null ? depth0.available_count : depth0), "result", "results", {"name":"pluralize","hash":{},"data":data})))
    + "\n                </p>\n            </div>\n            <div class=\"col-xs-6\">\n                <a class=\"share-search pull-right\"><i class=\"fa fa-share\"></i> Share results</a>\n            </div>\n        </div>\n";
},"3":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "no_results_message.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.available_count : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/entity_list_total.hbs'] = templateFunction;
              export default templateFunction;
              