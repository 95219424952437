export function getRelatedEntitiesView ($) {
  return Backbone.View.extend({
    initialize: function (params) {
      var collectionParams = params.type + '_id=' + params.id
      var title = 'Services provided at this&nbsp;site'

      if (params.type === 'service' || params.type === 'practitioner') {
        collectionParams = 'site_id=' + params.site.id + '&type=service'
        title = 'Other services provided at this&nbsp;site'
      }

      var relatedEntityCollection = new ISS.Models.EntityCollection()
      var tpl = JST[ISS.Var.templatePath + 'related_entities.hbs']

      relatedEntityCollection.fetch({
        data: collectionParams,
        success: function (collection, response) {
          collection.models = collection.sortBy('name')
          collection.models = collection.filter(function (entity) {
            return entity.id !== params.id
          })

          $('#relatedEntitiesContainer').append(tpl(jQuery.extend(
            collection,
            { relatedEntitiesLabel: title },
          )))

          if (collection.models.length) {
            $('#relatedEntitiesContainer').addClass('directory-details-info-row')
            $('.related-entity-spinner').remove()
          } else {
            $('#relatedEntitiesContainer').remove()
          }
        },
        error: function () {
          $('#relatedEntitiesContainer').remove()
        },
      })
    },
  })
}
