export function getPURViewModal ($) {
  return Backbone.View.extend({
    template: 'pur_modal.hbs',
    initialize: function () {
      var editable = this.model.getEditable()
      var tpl = JST[ISS.Var.templatePath + this.template]
      var egg = document.createElement('DIV')
      var hatched

      egg.innerHTML = tpl(editable)
      hatched = egg.firstChild

      this.el = hatched
      this.$el = $(this.el)
      this.delegateEvents()
    },

    events: {
      'click .submitPUR': 'submitPUR',
    },

    submitPUR: function (event) {
      event.preventDefault()
      var entity = this.model.attributes

      if (!HSNetUtils.validateForm($('#PURForm'))) {
        return false
      }

      var url = ISS.Main.makeIssUrl(
        'updaterequest/' + entity.type + '/' + entity.id + '/',
      )

      $('#PURForm .pur-spinner').css('display', 'inline-block')
      $.ajax({
        view: this,
        url: url,
        data: JSON.stringify(form2js('PURForm')),
        type: 'POST',
        contentType: 'application/json',
        xdrFields: {
          onerror: function () {
            // ie8 & ie9 handling
            // Unfortunately, ie has eatern our response json
            // so we can't do anything nice for the user
            $('#PURForm .pur-spinner').css('display', 'none')
            alert(
              'An unknown error occurred when submitting the form.\n\n' +
              'Form validation error handling is not supported in ' +
              'Internet Explorer versions 8 and 9, ' +
              'due to defects in those browsers.\n' +
              'Please ensure that your phone number has ' +
              'exactly 10 digits, ' +
              'and that your email address is correct.\n' +
              'All fields must be supplied in order to submit the form.\n' +
              'If you are having continued difficulty, ' +
              'please use another browser.',
            )
          },
        },
        statusCode: {
          400: function (jqXHR, textStatus, error) {
            $('#PURForm .pur-spinner').css('display', 'none')

            var response = JSON.parse(jqXHR.responseText)

            if ((!_.isUndefined(response)) && response.updaterequest) {
              var flattened = HSNetUtils.flattenObject(response.updaterequest)
              var validationMessages = {}

              _.each(
                flattened,
                function (message, key) {
                  // only act on the first validation message for each field
                  if (!_.isNull(key.match(/\.0$/))) {
                    var sansSuffix = key.replace(/\.0$/, '')
                    var selector = '#PURForm :input[name="' + sansSuffix + '"]'

                    if (sansSuffix === 'changes') {
                      selector = selector.replace(/=/, '^=')
                    }

                    var $field = $(selector)

                    if ($field.length) {
                      HSNetUtils.validateField($field, message)
                    }
                  }
                },
                validationMessages,
              )
            }
            return false
          },
          200: function (jqXHR, textStatus, error) {
            // ie 8 & 9
            // Oops, the HTTP response code was eaten by IE.
            // This is probably a 201 response.
            return this.statusCode['201'].call(this, jqXHR, textStatus, error)
          },
          201: function (jqXHR, textStatus, error) {
            $('#PURForm .pur-spinner').css('display', 'none')

            $('.modal-body', this.view.$el)
              .html('<h1 style="text-align: center;">Thank You!</h1>')

            setTimeout(function () {
              $('#PURModal .close-modal').click()
            },
            2000,
            )

            return false
          },
        },
      }).done(function (data) {
        // Unknown error
        $('#PURForm .pur-spinner').css('display', 'none')
        return false
      })

      return false
    },
  })
}
