
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<span>"
    + escapeExpression(((helper = (helper = helpers.searchFieldPlaceholder || (depth0 != null ? depth0.searchFieldPlaceholder : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchFieldPlaceholder","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.directoryRegion || (depth0 != null ? depth0.directoryRegion : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"directoryRegion","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"useData":true});
              Handlebars.templates['src/hbs/partials/text_below_search_input.hbs'] = templateFunction;
              export default templateFunction;
              