
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "      <div class=\"tt-suggestion\">";
  stack1 = lambda(depth0, depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"tt-predefined\">\n  <span class=\"tt-predefined-header\">\n    Suggestions:\n  </span>\n  <span class=\"tt-suggestions\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.suggestions : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </span>\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/predefined_keywords.hbs'] = templateFunction;
              export default templateFunction;
              