
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div id=\"PURModal\" class=\"modal fade\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n	<div class=\"modal-dialog service-directory-modal\">\n		<div class=\"modal-content\">\n			<div class=\"modal-header\">\n				<div class=\"col-sm-12  custom-col-sm-12 text-right\">\n					<i tabindex=\"0\" class=\"fa fa-times close-modal\" title=\"Close this dialog\" data-dismiss=\"modal\"></i>\n				</div>\n				<h1>Report an Error</h1>\n			</div>\n			<div class=\"modal-body\">\n			  "
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "pur_form.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n			</div>\n		</div><!-- /.modal-content -->\n	</div><!-- /.modal-dialog -->\n</div><!-- /.modal -->\n";
},"useData":true});
              Handlebars.templates['src/hbs/pur_modal.hbs'] = templateFunction;
              export default templateFunction;
              