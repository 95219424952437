
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.suburb : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.confidential : stack1), {"name":"if","hash":{},"fn":this.program(3, data),"inverse":this.program(5, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  return "						Confidential\n";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                        "
    + escapeExpression(((helpers.toCapital || (depth0 && depth0.toCapital) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.location : depth0)) != null ? stack1.suburb : stack1), {"name":"toCapital","hash":{},"data":data})))
    + "\n";
},"7":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "                &nbsp;"
    + escapeExpression(((helper = (helper = helpers.distance_to_location || (depth0 != null ? depth0.distance_to_location : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"distance_to_location","hash":{},"data":data}) : helper)))
    + "\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                    <dt><i class=\"fa fa-home\" title=\"Site icon\"></i></dt>\n                    <dd>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.site : depth0)) != null ? stack1.name : stack1), depth0))
    + "</dd>\n";
},"11":function(depth0,helpers,partials,data) {
  return "                    <dt><i class=\"fa fa-clock-o\" title=\"Clock icon\"></i></dt>\n                    <dd>Now open</dd>\n";
  },"13":function(depth0,helpers,partials,data) {
  return "                    <dt><i class=\"fa fa-check\" title=\"Check icon\"></i></dt>\n                    <dd>Part of National Disability Insurance Scheme</dd>\n";
  },"15":function(depth0,helpers,partials,data) {
  return "        <div class=\"flag-container\">\n            <span class=\"atsi-flag\">\n                <img src=\"/8a8f8764c29333c0c85c82de0b341095.svg\">\n            </span>\n            <span class=\"atsi-flag\">\n                <img src=\"/ded685c7d025d574ce1fe46c7ce5d324.svg\">\n            </span>\n            <div class=\"sr-only\">\n                Services for Aboriginal &amp; Torres Strait Islander people\n            </div>\n        </div>\n";
  },"17":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "        <div class=\"row\">\n            <div class=\"col-md-4\">\n                <a class=\"phone-link btn\" href=\"tel:"
    + escapeExpression(((helpers.onlyNumbers || (depth0 && depth0.onlyNumbers) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.phones : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.number : stack1), {"name":"onlyNumbers","hash":{},"data":data})))
    + "\">\n                    <i class=\"fa fa-phone\" title=\"Phone icon\"></i>Call "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.phones : depth0)) != null ? stack1['0'] : stack1)) != null ? stack1.number : stack1), depth0))
    + "\n                </a>\n            </div>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div data-id=\""
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"entity-list-item-mobile\">\n    <div class=\"row\">\n        <div class=\"col-md-4 title-dark\">\n            "
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-4 title-light\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.location : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.distance_to_location : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-4 description\">\n            "
    + escapeExpression(((helpers.subString || (depth0 && depth0.subString) || helperMissing).call(depth0, (depth0 != null ? depth0.description : depth0), 200, {"name":"subString","hash":{},"data":data})))
    + "\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-md-4\">\n            <a class=\"entity-details-view-link\" href=\"#services/"
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "/"
    + escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                More information <i class=\"fa fa-chevron-circle-right\"></i>\n            </a>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-md-4\">\n            <dl class=\"dl-horizontal\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.site : depth0), {"name":"if","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.now_open : depth0)) != null ? stack1.now_open : stack1), {"name":"if","hash":{},"fn":this.program(11, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.ndis_approved : depth0), {"name":"if","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </dl>\n        </div>\n    </div>\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.showIndigenousFlags : depth0), {"name":"if","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.phones : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/entity_list_item_mobile.hbs'] = templateFunction;
              export default templateFunction;
              