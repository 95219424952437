export function getSiteEntity ($) {
  return ISS.Models.EntityModel.extend({

    /**
     * Get the fields for contact information for this site.
     *
     *  Note that these fields need to appear in a specific order which differs
     *  from the order they should appear in for other entities.
     */
    getContactFields: function () {
      var fields = []
      var phones = this.attributes.phones

      // ISS Update can only handle the following phone numbers. If there are
      // more, we'll just ignore them.
      var phone1 = {
        value: '',
        name: 'phone1',
        label: 'Telephone 1',
        input: true,
        type: 'tel',
      }
      var phone1Comment = {
        value: '',
        name: 'phone1_comment',
        label: 'Telephone 1 Comment',
        input: true,
      }
      var phone2 = {
        value: '',
        name: 'phone2',
        label: 'Telephone 2',
        input: true,
        type: 'tel',
      }
      var phone2Comment = {
        value: '',
        name: 'phone2_comment',
        label: 'Telephone 2 Comment',
        input: true,
      }
      var mobile = {
        value: '',
        name: 'mobile',
        label: 'Mobile',
        input: true,
        type: 'tel',
      }
      var mobileComment = {
        value: '',
        name: 'mobile_comment',
        label: 'Mobile Comment',
        input: true,
      }
      var fax = {
        value: '',
        name: 'fax',
        label: 'Fax',
        input: true,
        type: 'tel',
      }
      var tty = {
        value: '',
        name: 'tty',
        label: 'TTY',
        input: true,
        type: 'tel',
      }
      var freecall = {
        value: '',
        name: 'freecall',
        label: 'Free Call',
        input: true,
        type: 'tel',
      }

      for (var i = 0; i < phones.length; i++) {
        var phone = phones[i]
        switch (phone.kind) {
          case 'phone':
            if (!phone1.value) {
              phone1.value = phone.number
              phone1Comment.value = phone.comment
            } else if (!phone2.value) {
              phone2.value = phone.number
              phone2Comment.value = phone.comment
            }
            break
          case 'mobile':
            mobile.value = phone.number
            mobileComment.value = phone.comment
            break
          case 'fax':
            fax.value = phone.number
            break
          case 'tty':
            tty.value = phone.number
            break
          case 'freecall':
            freecall.value = phone.number
            break
          default:
            break
        }
      }

      var emails = this.attributes.emails
      var email = {
        value: '',
        name: 'email',
        label: 'Email',
        input: true,
        type: 'email',
      }
      if (emails.length > 0) {
        email.value = emails[0].email
      }

      fields.push(phone1)
      fields.push(phone1Comment)
      fields.push(phone2)
      fields.push(phone2Comment)
      fields.push(mobile)
      fields.push(mobileComment)
      fields.push(fax)
      fields.push(tty)
      fields.push(freecall)
      fields.push(email)

      return fields
    },

    getFields: function () {
      var fields = [
        // Details of associated "Outlet" in ISS Update.
        {
          value: this.attributes.id,
          name: 'docid',
          hidden: true,
        },
        // Include the original name of the site.
        {
          value: this.prepareField(this.attributes.name),
          name: 'email_site_name',
          hidden: true,
        },

        // User's contact details.
        {
          value: Drupal.settings.user.name,
          name: 'contact_name',
          label: 'Your name',
          input: true,
          required: true,
          user_contact: true,
          readonly: true,
        },
        {
          value: Drupal.settings.user.email,
          name: 'contact_email',
          label: 'Your email',
          input: true,
          type: 'email',
          required: true,
          user_contact: true,
          readonly: true,
        },
        {
          value: Drupal.settings.user.phone,
          name: 'contact_phone',
          label: 'Your phone',
          input: true,
          type: 'tel',
          required: true,
          user_contact: true,
          readonly: true,
        },
        // Site details.
        {
          value: this.prepareField(this.attributes.name),
          name: 'name',
          label: 'Site Name',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.management_body),
          name: 'management_body',
          label: 'Management',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.ceo),
          name: 'ceo',
          label: 'CEO',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.description),
          name: 'description',
          label: 'Description',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.provider_type),
          name: 'provider_type',
          label: 'Provider type',
          choices: ['Community', 'Government', 'Private'],
        },

        // ISS3 entity 'location' corresponds to the
        // 'AGLS.Availability.address' portion of the ISS Update record.
        {
          name: 'street_address',
          label: 'Street Address',
          input: true,
          value: this.buildStreetAddress(this.attributes.location),
        },
        {
          value: this.prepareField(this.attributes.location.suburb),
          name: 'suburb',
          label: 'Suburb/Town',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.location.state),
          name: 'state',
          label: 'State',
          choices: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
        },
        {
          value: this.prepareField(this.attributes.location.postcode),
          name: 'postcode',
          label: 'Postcode',
          input: true,
          type: 'number',
        },

        // ISS3 entity 'postal_address' corresponds to the
        // 'AGLS.Availability.postalAddress' portion of the ISS Update record.
        {
          value: this.prepareField(this.attributes.postal_address[0].line1),
          name: 'mailing_address_line1',
          label: 'Postal Address Line 1',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.postal_address[0].line2),
          name: 'mailing_address_line2',
          label: 'Postal Address Line 2',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.postal_address[0].suburb),
          name: 'mailing_suburb',
          label: 'Postal Suburb',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.postal_address[0].state),
          name: 'mailing_state',
          label: 'Postal State',
          choices: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'],
        },
        {
          value: this.prepareField(this.attributes.postal_address[0].postcode),
          name: 'mailing_postcode',
          label: 'Postal Postcode',
          input: true,
          type: 'number',
        },
      ]

      // Insert the contact details specifically at this point in the form.
      fields = fields.concat(this.getContactFields())

      var remainingFields = [
        {
          value: this.prepareField(this.attributes.web),
          name: 'web',
          label: 'Web',
          input: true,
          type: 'url',
        },

        // TODO: office hours
        // TODO: catchment

        {
          value: this.prepareField(this.attributes.parking_info),
          name: 'parking_info',
          label: 'Parking',
          input: true,
        },
        {
          value: this.prepareField(this.attributes.public_transport_info),
          name: 'public_transport_info',
          label: 'Transport',
          textarea: true,
        },
        {
          value: this.prepareField(this.attributes.accessibility),
          name: 'accessibility',
          label: 'Accessibility',
          choices: [
            'Full wheelchair access',
            'Partial wheelchair access',
            'No wheelchair access',
          ],
        },
      ]
      return fields.concat(remainingFields)
    },
  })
}
