
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['with'].call(depth0, (depth0 != null ? depth0.location : depth0), {"name":"with","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", blockHelperMissing=helpers.blockHelperMissing, buffer = "		<span class=\"fa fa-map-marker\"></span>\n		"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "address_first_line.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n";
  stack1 = ((helpers.ifAny || (depth0 && depth0.ifAny) || helperMissing).call(depth0, (depth0 != null ? depth0.building : depth0), (depth0 != null ? depth0.flat_unit : depth0), (depth0 != null ? depth0.level : depth0), {"name":"ifAny","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.trimLeadingComma || (depth0 != null ? depth0.trimLeadingComma : depth0)) != null ? helper : helperMissing),(options={"name":"trimLeadingComma","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.trimLeadingComma) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  return "			<br>\n			<span class=\"fa fa-map-marker\" style=\"color: white;\"></span>\n";
  },"5":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "address_second_line.hbs", {"name":"partial","hash":{},"data":data})));
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.suburb : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"6":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return ", "
    + escapeExpression(((helper = (helper = helpers.suburb || (depth0 != null ? depth0.suburb : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"suburb","hash":{},"data":data}) : helper)));
},"8":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "	<br><br>\n	<span class=\"fa fa-map-marker\" style=\"color: white;\"></span> \n	"
    + escapeExpression(((helper = (helper = helpers.distance_to_location || (depth0 != null ? depth0.distance_to_location : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"distance_to_location","hash":{},"data":data}) : helper)))
    + "\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<h3>"
    + escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"name","hash":{},"data":data}) : helper)))
    + "</h3>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.location : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.distance_to_location : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"useData":true});
              Handlebars.templates['src/hbs/map_entity_details.hbs'] = templateFunction;
              export default templateFunction;
              