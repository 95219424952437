
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.street_number || (depth0 != null ? depth0.street_number : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"street_number","hash":{},"data":data}) : helper)))
    + "\n"
    + escapeExpression(((helper = (helper = helpers.street_name || (depth0 != null ? depth0.street_name : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"street_name","hash":{},"data":data}) : helper)))
    + "\n"
    + escapeExpression(((helper = (helper = helpers.street_suffix || (depth0 != null ? depth0.street_suffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"street_suffix","hash":{},"data":data}) : helper)))
    + "\n"
    + escapeExpression(((helper = (helper = helpers.street_type || (depth0 != null ? depth0.street_type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"street_type","hash":{},"data":data}) : helper)))
    + "\n";
},"useData":true});
              Handlebars.templates['src/hbs/partials/address_second_line.hbs'] = templateFunction;
              export default templateFunction;
              