// Polyfill for IE11 endsWith.
export function polyfillEndsWith () {
  if (!String.prototype.endsWith) {
    // eslint-disable-next-line no-extend-native
    String.prototype.endsWith = function (search, thisLen) {
      if (thisLen === undefined || thisLen > this.length) {
        thisLen = this.length
      }
      return this.substring(thisLen - search.length, thisLen) === search
    }
  }
}

export function polyfillIncludes () {
  if (!String.prototype.includes) {
    // eslint-disable-next-line no-extend-native
    String.prototype.includes = function (search, start) {
      'use strict'

      if (search instanceof RegExp) {
        throw TypeError('first argument must not be a RegExp')
      }
      if (start === undefined) { start = 0 }
      return this.indexOf(search, start) !== -1
    }
  }
}
