var Backbone = require('backbone')
var Handlebars = require('handlebars')
var leaflet = require('leaflet')
var underscore = require('underscore')
var jquery = require('jquery')
var moment = require('moment')
var form2js = require('form2js')
var flat = require('flat')

require('leaflet-sidebar')
require('leaflet-plugins/layer/tile/Google')
require('bootstrap-select/less/bootstrap-select.less')
// require('leaflet/dist/leaflet.css')
// require('./assets/img/marker-icon-red.png')
// require('leaflet/dist/images/marker-icon.png')
// require('leaflet-sidebar/src/L.Control.Sidebar.css')

window.$ = window.jQuery = jquery
window._ = underscore
window.L = leaflet
window.Backbone = Backbone
window.Handlebars = Handlebars
window.moment = moment
window.form2js = form2js.form2js
window.flatten = flat.flatten

Handlebars.templates = {}

// Base templates.
require('./src/hbs')

var baseTemplates = Handlebars.templates

// Overridden templates
require('../src/hbs')

// Merge the templates, with overridden templates taking precedent.
window.JST = {
  ...baseTemplates,
  ...Handlebars.templates,
}

require('typeahead.js/dist/typeahead.js')
// require('./src/styles/typeahead.css')

require('bootstrap')
require('bootstrap-paginator')
require('bootstrap-select')

// ISS JS custom scripts
require('./src/js/custom_helpers.js')
require('./src/js/map.js')
require('./src/js/services/index.js')
require('./src/js/suggestions.js')
require('./src/js/utils.js')
