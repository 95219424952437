/**
 * EntityModel is the model for individual search result items

  * Later, we could override parse() to provide some filtering of the
  * data. For example, making the suburb properly capitalised.
**/
export function getEntityModel ($) {
  return Backbone.Model.extend({
    url: function () {
      return ISS.Main.makeIssUrl(
        this.attributes.type + '/' + this.attributes.id + '/')
    },

    prepareField: function (str) {
      str = str || ''
      // Words that match search terms are ***highlighted***, but we
      // don't want to include the asterisks in the form data.
      return str.replace(/\*\*\*/ig, '')
    },

    buildStreetAddress: function (locationAttributes) {
      var requiredAttributes = [
        'building',
        'details',
        'flat_unit',
        'street_number',
        'street_name',
        'street_suffix',
        'street_type',
      ]

      var parent = this

      return _.reduce(requiredAttributes, function (carry, attr) {
        if (Object.prototype.hasOwnProperty.call(locationAttributes, attr)) {
          var value = parent.prepareField(locationAttributes[attr])
          if (value !== '') {
            // We don't want a leading space so check if carry is empty before
            // returning with a space.
            if (carry !== '') {
              return carry + ' ' + value
            }

            return value
          }
        }
        return carry
      }, '')
    },

    _ucwords: function (str) {
      str = str.toLowerCase()
      return str.replace(/^([a-z])|\s+([a-z])/g, function (chr) {
        return chr.toUpperCase()
      })
    },

    _isConfidential: function (location) {
      return Boolean((
        location.building +
        location.flat_unit +
        location.level +
        location.postcode +
        location.state +
        location.street_name +
        location.street_number +
        location.street_suffix +
        location.street_type +
        location.suburb +
        location.details
      ).match(/confidential|secret/i) || (!location.point && !location.crisis_point))
    },

    _distanceToLocation: function (point) {
      if (!point) {
        return null
      }

      var userLocation = ISSgeo.Var.userLocation

      // Search term location
      if (typeof this.collection !== 'undefined' &&
        typeof this.collection.meta.location !== 'undefined') {
        userLocation = {
          latitude: this.collection.meta.location.lat,
          longitude: this.collection.meta.location.lon,
        }
      }

      // distance in meters
      var distance = Math.round(new L.LatLng(userLocation.latitude, userLocation.longitude)
        .distanceTo(new L.LatLng(point.lat, point.lon)))

      if (distance <= 100) {
        return '<100 m'
      }

      if (distance > 100 && distance < 1000) {
        return distance.toString().substring(0, 1) + '00 m'
      }

      // distance is now kms
      distance = (distance / 1000)

      if (distance >= 1 && distance < 7) {
        return distance.toFixed(1) + ' km'
      }

      if (distance >= 7 && distance < 101) {
        return Math.round(distance) + ' km'
      }

      if (distance >= 101) {
        return distance.toFixed(0) + ' km'
      }
    },
    parse: function (response, options) {
      if (!response) {
        // entity not found
        return options.error()
      }

      // Ensure suburb capitalisation
      if ((typeof response.location !== 'undefined') &&
          (typeof response.location.suburb !== 'undefined')) {
        response.location.suburb = this._ucwords(response.location.suburb)
      }
      if (typeof response.postal_address !== 'undefined') {
        for (var i = 0; i < response.postal_address.length; ++i) {
          if (typeof response.postal_address[i].suburb !== 'undefined') {
            response.postal_address[i].suburb =
              this._ucwords(response.postal_address[i].suburb)
          }
        }
      }

      // Ensure location and the first postal address are defined.
      if (typeof response.location === 'undefined') {
        response.location = {}
      }
      if (typeof response.postal_address === 'undefined' ||
          typeof response.postal_address[0] === 'undefined') {
        response.postal_address = [{}]
      }

      // Ensure accessibility text
      if (typeof response.accessibility !== 'undefined') {
        var access = response.accessibility
        if (access === 'noaccess') {
          response.accessibility = 'No wheelchair access'
        } else if (access === 'access') {
          response.accessibility = 'Partial wheelchair access'
        } else if (access === 'fullaccess') {
          response.accessibility = 'Full wheelchair access'
        }
      }

      // distance to user location
      if (
        (typeof response.location !== 'undefined') &&
        (typeof response.location.point !== 'undefined')
      ) {
        response.distance_to_location =
          this._distanceToLocation(response.location.point)
      }

      // Check if location is confidential and set the confidential flag if it is.
      if (this._isConfidential(response.location)) {
        response.location.confidential = 'True'
      }

      // fix highlighting in the 'type' field (which is a bug in ISS3)
      if (typeof response.type !== 'undefined') {
        response.type = response.type.replace(/\*\*\*/g, '')
      }

      if (Array.isArray(response.indigenous_classification) && response.indigenous_classification.length > 0) {
        response.indigenous_classification = response.indigenous_classification[0]
      }

      response.showIndigenousFlags = (response.indigenous_classification ===
        'Mainstream who cater for Aboriginal (indigenous)') ||
        response.indigenous_classification === 'Aboriginal (indigenous) specific' ||
        response.indigenous_specific

      return response
    },
    getEditable: function () {
      var whitelist = {
        //        'accessibility': true,
        //        'accessibility_details': true,
        //        'accreditation': true,
        //        'age_groups': true,
        //        'also_known_as': true,
        //        'assessment_criteria': true,
        //        'availability': true,
        //        'billing_method': true,
        //        'cost': true,
        description: true,
        //        'eligibility_info': true,
        //        'emails': true,
        //        'funding_body': true,
        //        'healthcare_card_holders': true,
        //        'ineligibility_info': true,
        //        'intake_info': true,
        //        'intake_point': true,
        //        'is_bulk_billing': true,
        //        'languages': true,
        //        location : {
        //          'building' : true,
        //          'flat_number': true,
        //          'level': true,
        //          'postcode': true,
        //          'state': true,
        //          'street_name': true,
        //          'street_number': true,
        //          'street_suffix': true,
        //          'street_type': true,
        //          'suburb': true,
        //          'unit' : true
        //        },
        //        'name': true,
        //        'ndis_approved': true,
        //        'openening_hours': true,
        //        'ptarking_hours': true,
        //        'phones': true,
        //        'postal_address': true,
        //        'public_transport_info': true,
        //        'special_requirements': true,
        //        'web': true
      }
      var editable = {}

      _.each(this.attributes, function (value, key) {
        if (_.has(whitelist, key) === false) { return false }
        if (value === '') { return false }
        if ((value instanceof Array) && (value.length === 0)) { return false }
        if ((value instanceof Object) && (_.isEmpty(value))) { return false }

        if (value instanceof Array) {
          var arrayIndex = 0
          _.each(value, function (item) {
            if (typeof item === 'object') {
              _.each(item, function (objValue, objKey, item) {
                // using form2js format for objects within arrays
                var newKey = key + '[' + arrayIndex + '].' + objKey
                item[newKey] = objValue
                delete item[objKey]
              })
            }
            arrayIndex++
          })
        }
        editable[key] = value
      })
      return editable
    },
  })
}
