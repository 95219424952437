export function iosZoomFix () {
  // Prevent auto zoom on input fields in iOS.
  var iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  if (iOS) {
    var metaViewport = document.querySelector('meta[name=viewport]')
    if (metaViewport) {
      var viewportContent = metaViewport.getAttribute('content')
      metaViewport.setAttribute('content', viewportContent.concat(', maximum-scale=1'))
    }
  }
}
