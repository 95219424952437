export function getInitialISSContents ($) {
  return {
    Models: {},
    Views: {},
    Routers: {},
    Factories: {},
    ViewInstances: {
      entityDetailsViews: {},
      SearchPanelView: {},
    },
    EventMonitor: _.extend({}, Backbone.Events),
    Var: {
      templatePath: 'src/hbs/',
      routerMain: {},
      haveWeRunServicesOnce: false,
      serviceTypeSearch: false,
    },
    Main: {
      /**
       * Puts a Backbone view into the container (a html element).

       * Automatically detaches anything already in the container.
       * jquery detach() does not unbind event handlers... this is
       * the desired behaviour here, but call remove() on the view if
       * you want to free memory.
      **/
      appendViewToContainer: function (view) {
        var $container = $('#search-sidebar')
        if (ISS.Var.isMobile) {
          $('#as-mobile').removeClass('intro-page-mobile')
          $container = $('.search-result-container')
        }

        $container.scrollTop(0)

        $container.children().each(function () {
          $(this).detach()
        })
        $container.append(view.el)
      },
      doSearch: function (params, listParams) {
        if (typeof params.page !== 'undefined' && params.page) {
          listParams.page = params.page
        } else {
          listParams.page = 1
        }

        // Hide modal.
        $('#advancedSearchModal').modal('hide')
        // Display map view button.
        if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.SearchPanelView)) {
          ISS.ViewInstances.SearchPanelView.toggleViewMapButton(true)
        }

        // Spinner.
        ISS.Main.searchSpinner(true)

        if (listParams.queryObj.q === '' ||
            listParams.queryObj.q === 'undefined') {
          delete listParams.queryObj.q
        }

        // Add the user's location to the search query. This location is used
        // to find the area of the country in which to search for services.
        var location = ISSgeo.Var.userLocation
        if (location.longitude && location.latitude) {
          listParams.queryObj.location =
            location.longitude + 'E' +
            location.latitude + 'N'
        }

        var oldEntityListView = ISS.ViewInstances.entityListView
        var entityCollection = new ISS.Models.EntityCollection()
        ISS.ViewInstances.entityListView =
          ISS.Var.routerMain._viewFactory.createEntityListView({
            collection: entityCollection,
            params: listParams,
            successCallback: function (collection) {
              if (!HSNetUtils.isFalsyOrEmpty(oldEntityListView)) {
                oldEntityListView.close()
              }
              if (typeof params.successCallback === 'function') {
                params.successCallback(collection)
              }
            },
          })
        return false
      },
      submitSearch: function (params) {
        if (typeof params === 'undefined') {
          params = {}
        }

        var listParams = {
          queryObj: {},
          simpleSearchString: '',
          simpleLocationString: '',
        }

        // Filters not supported by ISS3 search strings.
        // If they are included in the users query, we need
        // to parse them out and apply them to the queryObj.
        var advancedFilters = {
          referral_enabled: '',
          ignore_catchment: '',
          language: '',
          service_type: '',
          search_type: '',
          indigenous_classification: '',
          include_nearby: '',
        }

        var forms = []

        var userProvidedQuery = $.trim($('#iss-keyword-search_input').val())

        // Split the user query by space, and search for the filters.
        var splitQuery = userProvidedQuery.split(' ')

        // Fill out the advanced_filters array with values found in the
        // user provided query.
        for (var k = 0; k < splitQuery.length; k++) {
          for (var key in advancedFilters) {
            if (splitQuery[k].startsWith(key + ':')) {
              advancedFilters[key] = splitQuery[k].split(key + ':')[1]
            }
          }
        }

        // The search_type filter may include spaces, so we need to find
        // the value set within quotes.  Eg.  service_type:"General practitioner".
        var serviceTypeIndex = userProvidedQuery.indexOf('service_type' + ':"')
        if (serviceTypeIndex > -1) {
          var serviceTypeIndexEnd = userProvidedQuery.indexOf('"', serviceTypeIndex + ('service_type' + ':"').length)
          advancedFilters.service_type = decodeURIComponent(
            userProvidedQuery.substring(
              serviceTypeIndex + ('service_type' + ':"').length,
              serviceTypeIndexEnd,
            ),
          )
        }

        if (advancedFilters.referral_enabled === 'true') {
          forms = ['hsnet_public']

          listParams.queryObj['referral.form'] = forms
        }

        // Ignoring the catchment means we should limit by
        // catchment.
        if (advancedFilters.ignore_catchment === '') {
          listParams.queryObj.catchment = false
        }

        if (advancedFilters.ignore_catchment === 'false') {
          listParams.queryObj.catchment = true
        }

        // By default, only include results which provide services in the
        // user's location. If they want to override this, they'll have to
        // use the advanced search form.
        if (advancedFilters.search_type) {
          listParams.queryObj.type = 'site'
        } else {
          listParams.queryObj.type = 'service'
        }

        if (advancedFilters.service_type) {
          // Remove any quoting of service type value.
          listParams.queryObj.service_type_raw = advancedFilters.service_type.replace(/"/g, '')
        }

        if (advancedFilters.language) {
          listParams.queryObj.language = advancedFilters.language
        }

        function setLocation ({ includeSurroundingAreas, location }) {
          if (!location) return
          const locationIsPostcode = String(location).match(new RegExp(HSNetUtils.postcode_regex))

          // ISS will ignore the area field if searching with no query or name so better to ignore the
          // user's preference of including results in surrounding area than for ISS to ignore
          // the user's search location all together
          if (includeSurroundingAreas && (listParams.queryObj.q || listParams.queryObj.name)) {
            listParams.queryObj.area = location
          } else {
            if (locationIsPostcode) {
              listParams.queryObj.location_postcode_raw = location
            } else {
              // Unfortunately it doesn't look like ISS3 supports a location_state_raw field so we just
              // have to ignore the state if given
              const [, suburb] = location.match(/^(.+?)(?:\s*,\s*(\w+))?$/) || [null, location]
              listParams.queryObj.location_suburb_raw = suburb
            }
          }
          listParams.simpleLocationString = location
        }

        if (ISS.Var.simpleSearch === true) {
          /**
          * Standard search
          **/
          listParams.queryObj.q = $('#iss-keyword-search_input').val()

          listParams.queryObj.q = removeFilter(
            listParams.queryObj.q,
            'service_type',
            advancedFilters.service_type,
          )

          listParams.queryObj.q = removeFilter(
            listParams.queryObj.q,
            'ignore_catchment',
            advancedFilters.ignore_catchment,
          )

          listParams.queryObj.q = removeFilter(
            listParams.queryObj.q,
            'language',
            advancedFilters.language,
          )

          listParams.queryObj.q = removeFilter(
            listParams.queryObj.q,
            'search_type',
            advancedFilters.search_type,
          )

          listParams.queryObj.q = removeFilter(
            listParams.queryObj.q,
            'referral_enabled',
            advancedFilters.referral_enabled,
          )

          listParams.queryObj.q = removeFilter(
            listParams.queryObj.q,
            'include_nearby',
            advancedFilters.include_nearby,
          )

          // Location
          setLocation({
            includeSurroundingAreas: advancedFilters.include_nearby !== 'false',
            location: $('#iss-location-search_input').val().trim(),
          })

          // If site search, look for words sitename within parenthesis.
          if (listParams.queryObj.type === 'site') {
            var startSiteName = listParams.queryObj.q.indexOf('(')
            var endSiteName = listParams.queryObj.q.indexOf(')')
            var siteName = listParams.queryObj.q.substring(startSiteName, endSiteName + 1)
            listParams.queryObj.name = siteName
            listParams.queryObj.q = listParams.queryObj.q.replace(siteName, '')
          }

          // We need to encode this for things like '/' in service types.
          listParams.simpleSearchString = encodeURIComponent(userProvidedQuery)

          listParams.queryObj.q = $.trim(listParams.queryObj.q)

          if (!ISS.Var.isMobile) {
            $('#advancedSearchModal').remove()
          } else {
            $('#advancedSearchMobileContainer').remove()
          }
        } else {
          /**
          * Advanced search
          **/
          $('#iss-keyword-search_input').val('')

          var formData = $('#issAdvancedSearchForm').find(':input').serializeArray()
          var data = {}
          var formDataObject = {}
          var value = ''

          for (var i = 0; i < formData.length; i++) {
            formDataObject = formData[i]
            value = $.trim(formDataObject.value)
            // Escape elastic operators. Unless value is a service type.
            if (formDataObject.name !== 'serviceTypeInput') {
              value = HSNetUtils.escapeElasticOperators(value)
            }
            if (_.has(data, formDataObject.name)) {
              if (!(data[formDataObject.name] instanceof Array)) {
                data[formDataObject.name] = [data[formDataObject.name]]
              }
              data[formDataObject.name].push(value)
            } else {
              data[formDataObject.name] = value
            }
          }

          // Sites only, otherwise always only display services.
          var sitesOnly = data.sitesOnly === 'sites_only'
          if (sitesOnly) {
            listParams.queryObj.type = 'site'
          } else {
            listParams.queryObj.type = 'service'
          }

          // words filter
          listParams.queryObj.q = ''
          var str = ''
          var regExp = /^|[^a-zA-Z0-9_']+/g

          if (!HSNetUtils.validateForm($('#issAdvancedSearchForm'))) {
            return false
          } else {
            switch (data.wordFilterTypeSelect) {
              case 'All words':
                str = data.searchForInput.replace(regExp, ' +')
                listParams.queryObj.q += str
                break
              case 'Any words':
                if (sitesOnly) {
                  listParams.queryObj.name = '(' + data.searchForInput.trim() + ')'
                  listParams.simpleSearchString += listParams.queryObj.name
                } else {
                  listParams.queryObj.q += data.searchForInput
                }
                break
              case 'Exact phrase':
                listParams.queryObj.q += '"' + data.searchForInput + '"'
                break
            }
          }

          // Exclude words.
          if (data.excludedWordsInput !== '') {
            str = data.excludedWordsInput.replace(regExp, ' -')
            listParams.queryObj.q += str
          }

          listParams.simpleSearchString += listParams.queryObj.q
          listParams.queryObj.q = $.trim(listParams.queryObj.q)

          // Location
          setLocation({
            includeSurroundingAreas: data.locationTypeSelect === 'near_location',
            location: data.locationInput,
          })

          // Do not includes results nearby but outside of the location
          if (data.locationTypeSelect === 'in_suburb_postcode') {
            listParams.simpleSearchString += ' include_nearby:false'
          }

          // Specific gender services.
          if (data.genderSelect !== 'any' &&
            typeof data.genderSelect !== 'undefined'
          ) {
            listParams.queryObj.client_gender = data.genderSelect
            listParams.simpleSearchString += ' client_gender:' + listParams.queryObj.client_gender
          }

          // Specific aboriginal services.
          if (data.specificSelect !== '') {
            if (data.specificSelect === 'aboriginalspecific') {
              // We have a specific query, that contains two values.  An array doesn't seem
              // to filter the results properly here.
              var indigenousQuery = ' indigenous_classification: specific indigenous_classification: cater'
              listParams.queryObj.q += indigenousQuery
              listParams.simpleSearchString += indigenousQuery
            }
            if (data.specificSelect === 'caldspecific') {
              listParams.queryObj.cald_specific = 'true'
              listParams.simpleSearchString += ' cald_specific:' + listParams.queryObj.cald_specific
            }
          }

          // Language.
          if (data.spokenLanguagesInput !== '' &&
            data.spokenLanguagesInput.toLowerCase() !== 'english'
          ) {
            listParams.queryObj.language = data.spokenLanguagesInput
            listParams.simpleSearchString += ' language:' + listParams.queryObj.language
          }

          // Age groups.
          if (data.serviceForSelect !== 'any' &&
            typeof data.serviceForSelect !== 'undefined'
          ) {
            listParams.queryObj.age_group = data.serviceForSelect
            if (data.serviceForSelect instanceof Array) {
              for (var j = 0; j < data.serviceForSelect.length; j++) {
                listParams.simpleSearchString += ' age_group:' + data.serviceForSelect[j]
              }
            } else {
              listParams.simpleSearchString += ' age_group:' + listParams.queryObj.age_group
            }
          }

          // Search by site name.
          if (sitesOnly) {
            listParams.simpleSearchString += ' search_type:site'
          }

          var ignoreCatchment = data.catchmentCheck === 'exclude'
          if (!ignoreCatchment) {
            // Note that "catchment:true" and/or "catchment:false" in the
            // search string are not currently supported by ISS3, so don't set
            // them on the `simpleSearchString`.
            listParams.queryObj.catchment = true
            listParams.simpleSearchString += ' ignore_catchment:false'
          } else {
            listParams.queryObj.catchment = false
          }

          // Only show referral enabled services. If not logged in, show
          // services enabled for public referral
          var referralEnabledOnly = data.referralEnabledOnly === 'referral_enabled'
          if (referralEnabledOnly) {
            forms = ['hsnet_public']

            listParams.queryObj['referral.form'] = forms
            listParams.simpleSearchString += ' referral_enabled:true'
          }

          // site accessibility
          /* if (data.accessibilitySelect !== 'any') {
            listParams.queryObj.accessibility = data.accessibilitySelect;
            listParams.simpleSearchString += ' accessibility:' + listParams.queryObj.accessibility;
          } */

          // Service Type.
          if (data.serviceTypeInput !== '') {
            listParams.queryObj.service_type_raw = data.serviceTypeInput
            listParams.simpleSearchString += ' service_type:"' + encodeURIComponent(data.serviceTypeInput) + '"'
          }

          listParams.simpleSearchString = listParams.simpleSearchString.trim()
          listParams.simpleLocationString = listParams.simpleLocationString.trim()

          // Add search terms to simple search box.
          // It will be available inside the listView instance for later.
          $('#iss-keyword-search_input').val(listParams.simpleSearchString)
          $('#iss-location-search_input').val(listParams.simpleLocationString)
        }

        const locationString = listParams.queryObj.area || listParams.simpleLocationString
        if (!listParams.simpleSearchString && locationString) {
          // ISS3 gets confused if the location has a state so if it has one then convert the
          // location to postcode if possible, otherwise drop the state
          const [, suburb, state] = locationString.match(/^(.+?)(?:\s*,\s*(\w+))?$/) || [null, locationString]
          if (state) {
            const suggestionsUrl = ISS.Main.makeIssUrl(
              'location/search/',
              {
                name: suburb,
                kind: ['suburb', 'postcode'],
                state: state.toUpperCase(),
              },
            )
            fetch(suggestionsUrl)
              .then(res => res.json())
              .then(data => {
                const location = data.objects
                  .find(location => (
                    location.name.toLowerCase() === suburb.toLowerCase() &&
                    location.state.toLowerCase() === state.toLowerCase() &&
                    location.postcode
                  ))
                if (location && location.postcode) {
                  goToServiceTypesPage(location && location.postcode)
                } else {
                  goToServiceTypesPage(suburb)
                }
              })
              .catch(() => goToServiceTypesPage(suburb))
          } else {
            goToServiceTypesPage(suburb)
          }
          return false
        }

        // Do search with our params.
        ISS.Main.doSearch(params, listParams)

        return false
      },
      doServiceTypeSearch: function (params) {
        // Create the correct list param object
        // load the advanced search
        ISS.ViewInstances.SearchPanelView.renderAdvancedSearch(true)
        // preset advanced search form details
        ISS.ViewInstances.SearchPanelView.clearAdvancedSearchForm()

        $('#sitesOnly').prop('checked', false)
        $('#locationTypeSelect').val('in_suburb_postcode')
        $('#locationInput').val(params.area)
        $('#catchmentCheck').prop('checked', true)

        // $('#serviceTypeInput') options are loaded via ajax
        // if nothing is loaded, lets add it first.
        // The ajax loader will handle the potential dupe.
        var serviceTypeInput = $('#serviceTypeInput')
        var optionExists = (
          $('#serviceTypeInput option[value="' + $('<div/>').text(params.service_type_value).html() + '"]').length > 0
        )
        if (!optionExists) {
          var $option = $('<option>')
          $option
            .val(params.service_type_value)
            .text(params.service_type_value)
          $(serviceTypeInput).append($option)
        }
        $(serviceTypeInput).val(params.service_type_value).change()
        ISS.ViewInstances.SearchPanelView.refreshServiceTypePicker()
        $('#issAdvancedSearchForm').submit()
      },
      showSideBar: function () {
        if (!ISS.Var.isMobile) {
          ISSgeo.Var.sideBar.show()
          $('.leaflet-sidebar').show()

          ISSgeo.Main.hideOverlay()
        } else if (ISS.Var.isMobile && !ISS.Var.simpleSearch) {
          // hide advanced search mobile
          $('#advancedSearchMobileContainer').hide()
          $('#services-search').show()
        }
      },
      /**
       * Gets the id of the entity by looking for a data-id="" in the DOM.

       * If ISS.ViewInstances.entityListView.collection doesn't have the model
       * for the id, then this will die. It is expected that the displayed
       * EntityListView will contain all the models we want to know about.

       * Calls appendViewToContainer() ... meaning that the View is displayed
       * immediately.

      **/
      displayEntityDetails: function (eventObject) {
        var entityId
        if (eventObject !== null && typeof eventObject === 'object') {
          var entitycontainer = '.entity-list-item'
          if (ISS.Var.isMobile) {
            entitycontainer = '.entity-list-item-mobile'
          }
          entityId =
            $(eventObject.target).parents(entitycontainer).first().data('id')
        } else {
          // eventObject is a string
          entityId = eventObject
        }

        var entity = ISS.ViewInstances.entityListView.collection.get(entityId)
        this.displayEntity(entity)

        return false
      },
      displayEntity: function (entity, args) {
        if (typeof args !== 'object') {
          args = {}
        }

        var $searchSidebar = $('#search-sidebar')
        if ($searchSidebar.length > 0) {
          ISS.listScrollPos = $searchSidebar.scrollTop()
        }
        if (!HSNetUtils.isFalsyOrEmpty(ISS.ViewInstances.entityDetailsView)) {
          ISS.ViewInstances.entityDetailsView.close()
        }
        ISS.ViewInstances.entityDetailsView =
          ISS.Var.routerMain._viewFactory.createEntityDetailsView({ model: entity })

        // Not for mobile
        if (!ISS.Var.isMobile) {
          ISS.Main.appendViewToContainer(ISS.ViewInstances.entityDetailsView)
          // open entity popup in map
          ISSgeo.Main.openEntityPopup(entity)
        } else {
          // Mobile Only
          if ($('#entityViewList').length > 0) {
            $('#entityViewList').detach()
          } else {
            $('#service-directory-container').detach()
          }

          $('#wrap').html(ISS.ViewInstances.entityDetailsView.el)
        }

        $('#directory-details-body-tabs a').click(function (e) {
          e.preventDefault()
          $(this).tab('show')
          return false
        })

        $('.directory-details-controls-print').click(function (e) {
          e.preventDefault()
          window.print()
        })

        // Enable tooltip
        $('[data-toggle="tooltip"]').tooltip()

        ISS.Var.routerMain.navigate('services/' + entity.attributes.type + '/' + entity.id, { trigger: false })
        document.title = entity.attributes.name + ' | ' + ISS.Var.options.get('overlayDirectoryName')
        HSNetUtils.history.add()
      },
      searchSpinner: function (enable) {
        if (enable) {
          if (ISS.Var.isMobile) {
            $('.mobile-spinner').show().addClass('mobile-overlay')
            $('.mobile-spinner .fa-refresh').addClass('fa-spin')
          } else {
            $('.fa-refresh').addClass('fa-spin')
            $('.search-spinner').css('display', 'inline-block')
          }
        } else {
          if (ISS.Var.isMobile) {
            $('.mobile-spinner').removeClass('mobile-overlay').hide()
            $('.mobile-spinner .fa-refresh').removeClass('fa-spin')
          } else {
            $('.fa-refresh').removeClass('fa-spin')
            $('.search-spinner').css('display', 'none')
          }
        }
      },
      /**
       * A generic modal for alerts, etc.
       * data = {title:'',content:'',footer:''}
       * options are passed to Bootstrap .modal() call, together with 'show'
       */
      displayGenericModal: function (data, options) {
        if (!$('#genericModal').length) {
          var template = JST[ISS.Var.templatePath + 'generic_modal.hbs']
          $('body').append(template(data))
        }

        if (typeof options === 'undefined') {
          options = {}
        }
        options.show = true

        $('#genericModal').modal(options)
      },
      highlightSearchTerms: function (str) {
        return str.replace(/\*\*\*\b/g, '<strong>')
          .replace(/\b\*\*\*/g, '</strong>')
      },
      displayMobileMap: function (display) {
        if (display) {
          $('.search-result-container').hide()
          $('#mapMobileContainer').show()
          $('#issSearchResultsMobileLnk').text('View results list')
          ISSgeo.Main.resizeMap()
          ISSgeo.Main.zoomToResults()
        } else {
          $('.search-result-container').show()
          $('#mapMobileContainer').hide()
          $('#issSearchResultsMobileLnk').text('View map results')
        }
      },
      isServiceTypesListView: function () {
        return HSNetUtils.history.currentState() !== null &&
          HSNetUtils.history.currentState().startsWith('/#services/servicetypes')
      },
      makeIssUrl: function (path, getParams, endpoint = 'v3') {
        var issUrl = ISS.Var.options.get('issUrl')

        // Override the iss3 version endpoint for other resources.
        if (endpoint !== 'v3') {
          issUrl = issUrl.replace('/v3', endpoint)
        }

        issUrl += path

        var dupeParams = ''
        if (_.isObject(getParams)) {
          _.each(getParams, function (value, key, list) {
            if (_.isArray(value)) {
              _.each(value, function (element, index, list) {
                dupeParams += '&' + key + '=' + element
              })

              delete list[key]
            }
          })
        }

        getParams = getParams || {}
        getParams.key = ISS.Var.options.get('issKey')
        issUrl += '?' + $.param(getParams)

        issUrl = issUrl.replace('%25', '%')

        issUrl += dupeParams

        return issUrl
      },
    },
  }
}

function removeFilter (query, fieldNameToMatch, fieldValueToMatch) {
  if (!fieldValueToMatch) return query
  const fieldName = sanitiseInputForRegex(fieldNameToMatch)
  const fieldValue = sanitiseInputForRegex(fieldValueToMatch)
  const fieldValueHasSpace = Boolean(fieldValue.match(' '))
  return query.replace(
    // If fieldValue doesn't have a space then also try to match it without quotes around it
    new RegExp(`(?:\\s+|^)${fieldName}:(?:"${fieldValue}"${fieldValueHasSpace ? '' : '|' + fieldValue})`, 'g'),
    '',
  )
}

function sanitiseInputForRegex (input) {
  return input.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&')
}

function goToServiceTypesPage (location) {
  // We need to base64 encode the query string because there
  // is an outstanding bug in backbone.navigate() which will fire
  // handlers twice if a space or other special characters appear
  // https://github.com/jashkenas/backbone/pull/4175
  // https://github.com/jashkenas/backbone/issues/4085
  const encodedLocation = location.match(new RegExp(HSNetUtils.postcode_regex))
    ? location
    : window.btoa(location)
  ISS.Var.routerMain.navigate(
    'services/servicetypes/' + encodedLocation,
    { trigger: true },
  )
}
