
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.requestor || (depth0 != null ? depth0.requestor : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"requestor","hash":{},"data":data}) : helper)));
  },"3":function(depth0,helpers,partials,data) {
  return "map";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div id=\"entityDetailsMobileContainer\" class=\"entity-details-mobile\">\n	<div class=\"top-panel-mobile\">\n		"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "mobile_logo_bar.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n		<a id=\"backFromEntityDetails\" class=\"back-menu-mobile\" href=\"javascript:;\" title=\"Back to ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.requestor : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n			<span class=\"fa fa-arrow-left\"></span> Back to ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.requestor : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n		</a>\n	</div>\n\n	"
    + escapeExpression(((helpers.partial || (depth0 && depth0.partial) || helperMissing).call(depth0, "entity_details.hbs", {"name":"partial","hash":{},"data":data})))
    + "\n\n</div>\n";
},"useData":true});
              Handlebars.templates['src/hbs/details_mobile.hbs'] = templateFunction;
              export default templateFunction;
              