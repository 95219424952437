export function getShareModalView ($) {
  return Backbone.View.extend({
    events: {
      'click .share-copy-link': 'copyEntityUrl',
    },
    template: function () {
      return JST[ISS.Var.templatePath + 'share_modal.hbs']
    },
    initialize: function () {
      let entity = {}
      let copyUrl = ''
      let shareUrl = ''
      let emailUrl = ''
      const description = this.model.description
      let type = ''

      // Is this a site/service, or a search?
      if (this.model.entity !== undefined) {
        entity = this.model.entity
        type = entity.type
        shareUrl = window.location.protocol +
        '//' + window.location.host +
        '/#services/' + entity.type +
        '/' + entity.id
      } else {
        type = 'search'
        shareUrl = window.location
      }
      copyUrl = shareUrl
      shareUrl = encodeURIComponent(shareUrl)
      emailUrl = '<' + shareUrl + '>'

      const renderedTemplate = this.template()({
        entity: entity,
        description: description,
        copyUrl: copyUrl,
        shareUrl: shareUrl,
        emailUrl: emailUrl,
        type: type,
      })

      var container = document.createElement('div')
      container.innerHTML = renderedTemplate
      this.el = container.firstChild
      this.$el = $(this.el)
      this.delegateEvents()
    },
    copyEntityUrl: function () {
      $('.share-modal input.entity-url').focus().select()
      $('.share-modal .share-copy-link').addClass('copied')
      window.clearTimeout(this.timer)
      this.timer = setTimeout(function () {
        $('.share-modal .share-copy-link').removeClass('copied')
      }, 2000)
      HSNetUtils.copyToClipboard()
    },
  })
}
