
              const Handlebars = require('handlebars');
              const templateFunction = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.flat_unit : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.level : depth0), {"name":"if","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.building : depth0), {"name":"if","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"2":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return ", "
    + escapeExpression(((helpers.contextualise || (depth0 && depth0.contextualise) || helperMissing).call(depth0, (depth0 != null ? depth0.flat_unit : depth0), "Unit", {"name":"contextualise","hash":{},"data":data})));
},"4":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return ", "
    + escapeExpression(((helpers.contextualise || (depth0 && depth0.contextualise) || helperMissing).call(depth0, (depth0 != null ? depth0.level : depth0), "Level", {"name":"contextualise","hash":{},"data":data})));
},"6":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return ", "
    + escapeExpression(((helpers.contextualise || (depth0 && depth0.contextualise) || helperMissing).call(depth0, (depth0 != null ? depth0.building : depth0), "Building", {"name":"contextualise","hash":{},"data":data})));
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing, buffer = "";
  stack1 = ((helper = (helper = helpers.trimLeadingComma || (depth0 != null ? depth0.trimLeadingComma : depth0)) != null ? helper : helperMissing),(options={"name":"trimLeadingComma","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.trimLeadingComma) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
              Handlebars.templates['src/hbs/partials/address_first_line.hbs'] = templateFunction;
              export default templateFunction;
              