(function ($) {
  'use strict'

  window.HSNetUtils = {
    escapeHtmlEntities: function (str) {
      return str
        .replace(/&/g, '&amp;')
        .replace(/>/g, '&gt;')
        .replace(/</g, '&lt;')
        .replace(/"/g, '&quot;')
    },
    escapeElasticOperators: function (str) {
      return str
        .replace(/\+/g, '\\+')
        .replace(/-/g, '\\-')
        .replace(/\*/g, '\\*')
        .replace(/"/g, '\\"')
        .replace(/\|/g, '\\|')
        .replace(/\(/g, '\\(')
        .replace(/\)/g, '\\)')
    },
    unescapeElasticOperators: function (str) {
      return str
        .replace(/\\\+/g, '+')
        .replace(/\\-/g, '-')
        .replace(/\\\*/g, '*')
        .replace(/\\"/g, '"')
        .replace(/\\\|/g, '|')
        .replace(/\\\(/g, '(')
        .replace(/\\\)/g, ')')
    },
    htmlDecode: function (str) {
      return $('<div/>').html(str).text()
    },
    htmlEncode: function (str) {
      return $('<div/>').text(str).html()
    },
    isFalsyOrEmpty: function (obj) {
      return !obj || $.isEmptyObject(obj)
    },
    cloneAsObject: function (obj) {
      if (obj === null || !(obj instanceof Object)) {
        return obj
      }
      var temp = (obj instanceof Array) ? [] : {}
      for (var key in obj) {
        temp[key] = HSNetUtils.cloneAsObject(obj[key])
      }
      return temp
    },
    /**
     * Gets a boolean value indicating whether the client is a mobile device or not.
     * @returns boolean
     */
    isMobileDevice: function () {
      // for now we depend on viewport width to detect whether it is a mobile device or not
      var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

      // 767 is the bootstrap's xs-max-width
      return (viewportWidth <= 767)

      // TODO: temporarily commented
      // return Modernizr.touch || Modernizr.hasEvent('deviceOrientation');
    },
    history: {
      history: [],
      historyIdx: -1,
      add: function () {
        var state = window.location.pathname + window.location.hash
        this.historyIdx++
        this.history[this.historyIdx] = state
      },
      getPreviousState: function () {
        if (this.historyIdx >= 1) {
          return this.history[this.historyIdx - 1]
        }
        return null
      },
      currentState: function () {
        if (this.historyIdx >= 0) {
          return this.history[this.historyIdx]
        }
        return null
      },
    },
    /**
     * Copies the current selection to the clipboard.
     */
    copyToClipboard: function () {
      try {
        return document.execCommand('copy')
      } catch (err) {
        return false
      }
    },
    validateField: function ($field, explicitInvalidationMessage) {
      var invalidClass = 'has-error custom-field-error'

      function applyInvalid (_defaultMessage) {
        var defaultMessage = (_defaultMessage) || 'Error'
        var validationMessage =
          ($field.data('validation-message'))
            ? $field.data('validation-message') : defaultMessage

        if (!$field.parent().hasClass(invalidClass)) {
          $field.parent().addClass(invalidClass).append(
            '<div class="help-block italic validation-message">' +
            validationMessage +
            '</div>',
          )
        }
      }

      function applyValid () {
        $field.parent().removeClass(invalidClass)
        $('.validation-message', $field.parent()).remove()
      }

      if (explicitInvalidationMessage) {
        applyInvalid(explicitInvalidationMessage)
        return false
      } else if ($field.hasClass('validate-emptiness')) {
        if ($field.val().length < 1) {
          applyInvalid('This field cannot be left blank.')
          return false
        } else {
          applyValid()
        }
      } else {
        // this element was marked invalid, but there is no validation rule
        // assume that it was marked invalid by another script
        // and mark it valid for now
        applyValid()
      }

      return true
    },
    validateForm: function ($form) {
      var Utils = this
      var isFormValid = true
      $(':input', $form).each(function () {
        var isFieldValid = Utils.validateField($(this))
        isFormValid = isFormValid && isFieldValid
      })
      $(':input', $('error', $form).first()).focus()

      return isFormValid
    },
    isInViewport: function (el) {
      var elem = $(el)
      var win = $(window)

      var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft(),
      }
      viewport.right = viewport.left + win.width()
      viewport.bottom = viewport.top + win.height()

      var bounds = elem.offset()
      bounds.right = bounds.left + elem.outerWidth()
      bounds.bottom = bounds.top + elem.outerHeight()

      return (
        !(
          viewport.right < bounds.left ||
          viewport.left > bounds.right ||
          viewport.bottom < bounds.top ||
          viewport.top > bounds.bottom
        )
      )
    },
    postcode_regex: '^[0-9]{4}$',
    base64_regex: '^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$',
    flattenObject: function (ob) {
      return window.flatten(ob)
    },
  }

  Backbone.View.prototype.close = function () {
    this.remove()
    this.unbind()
    if (this.onClose) {
      this.onClose()
    }
  }
})(jQuery)
